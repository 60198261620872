export default {
    open: 'Otwierasz',
    close: 'Zamknąć',
    apply: 'Stosować',
    cancel: 'Anulować',
    search: 'W pobliżu',
    cancel_search: 'Anuluj wyszukiwanie',
    edit_search: 'Edytuj wyszukiwanie',
    erase: 'Anulować',
    back_to_results: 'Wróć do wyników',
    save: 'Ratować',
    retry: 'Spróbuj ponownie',
    click_here: 'Kliknij tutaj',
    copy: 'Kopiuj',
    copied: 'Skopiowano',
    copied_coordinates: 'Współrzędne skopiowane',
    select: 'Wybierać',
    selected: 'Wybrany',
    selected_plural: 'Wybrany',
    deselect: 'Odznacz',
    select_multi_vehicles: 'Wybierz jeden lub więcej pojazdów',
    include: 'Włączać',
    exclude: 'Wykluczać',
    balanced: 'Zrównoważony',
    input: 'Wejście',
    output: 'Wyjście',
    verified: 'Zweryfikowano',
    empty_search: 'Nie znaleziono wyników',
    read_more: 'Czytaj więcej',
    details: 'Detale',
    actual: 'Aktualny',
    residual_time: 'Pozostały czas',
    next: 'Następny',
    previous: 'Poprzedni',
    menu_open: 'Otwórz menu',
    hide: 'Ukrywać',
    show: 'Pokazywać',
    none: 'Nikt',
    none_alt: 'Nic',
    all: 'Wszyscy',
    all_alt: 'Wszystko',
    default: 'Domyślny',
    settings: 'Ustawienia',
    reset_settings: 'Resetowanie ustawień',
    reset_settings_success: 'Ustawienia zostały zresetowane pomyślnie',
    multiselect_default_value: {
        singular: 'Wybrana wartość:',
        plural: 'Wybrane wartości:'
    },
    available: 'Dostępny',
    unavailable: 'Niedostępne',
    not_available: 'Niedostępne',
    not_available_plural: 'Niedostępne',
    from: 'z',
    to: 'do',
    of: 'Z',
    yes: 'Tak',
    no: 'NIE',
    minutes: 'Minuty',
    hours: 'godziny',
    hour: 'Teraz',
    days: 'Dni',
    day: 'Dzień',
    months: 'Miesiące',
    month: 'Miesiąc',
    years: 'Lata',
    year: 'Rok',
    days_names: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    days_names_short: ['Słońce', 'pon', 'Zniszczyć', 'Poślubić', 'czwartek', 'piątek', 'sob'],
    months_names: [
        'Styczeń',
        'Luty',
        'Marsz',
        'Kwiecień',
        'Móc',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
    ],
    months_names_short: [
        'gen',
        'luty',
        'Zniszczyć',
        'kwiecień',
        'Mag',
        'Poniżej',
        'lipiec',
        'sierpień',
        'Ustawić',
        'paź',
        'listopad',
        'grudzień'
    ],
    areas: 'Obszary',
    area: 'Obszar',
    time: 'Czas (min)',
    time_label: 'Czas',
    cost: 'Koszt',
    toll_cost: 'Koszt opłaty drogowej',
    km_cost: 'Koszt za km',
    hourly_cost: 'Koszt godzinowy',
    working_cost: 'Całkowity koszt człowieka',
    total_cost: 'Całkowity koszt',
    expire_date: 'Termin ważności',
    start_date: 'Data rozpoczęcia',
    recap: 'Streszczenie',
    start: 'Początek',
    starting_point: 'Wyjazd',
    end: 'koniec',
    finish_point: 'przybywam',
    duration: 'Czas trwania',
    distance: 'Dystans',
    daily_distance: 'km przejechanych codziennie',
    distance_total: 'Całkowity dystans',
    gps_distance: 'Odległość (GPS)',
    distance_gps: 'Przejechane kilometry dziennie (GPS)',
    pause: 'Przerwa',
    segment: 'Zajmuje się',
    start_time: 'Czas rozpoczęcia',
    end_time: 'Koniec czasu',
    service_time: 'Czas naprawy',
    waypoint_load: 'Załadunek na pokład',
    state: 'Państwo',
    violated_plafond: 'Naruszenie sufitu',
    traffic_delay: 'Opóźnienie w ruchu',
    company: 'Agencja',
    address: 'Adres',
    position: 'Pozycja',
    route: 'Ścieżka',
    filter_date: 'Filtruj według daty',
    warning: 'Uwaga',
    no_warning: 'Żadnych ostrzeżeń',
    alerts: 'Uwagi',
    trucks_with_alerts: 'Pojazdy z ostrzeżeniami',
    bluetooth_devices: 'Urządzenia Bluetooth',
    no_bluetooth_devices: 'Nie znaleziono urządzeń Bluetooth',
    connected: 'Połączony',
    disconnected: 'Bezładny',
    bluetooth_types: {
        temperature_probe: 'Sonda temperatury',
        door_probe: 'Czujnik drzwi i temperatury'
    },
    back_to_home: 'Wrócić do domu',
    cerca: 'W pobliżu',
    search_vehicle: 'Wyszukaj pojazd',
    filter: 'Filtr',
    filter_label: 'Filtr',
    filters_label: 'Filtry',
    filter_by: 'Filtruj według',
    operations: 'Operacje',
    filter_vehicles: 'Filtruj pojazdy',
    filter_applied: 'Filtr został zastosowany pomyślnie',
    filter_canceled: 'Filtr został pomyślnie usunięty',
    order_by: 'Sortuj według',
    order: 'Zamówienie',
    ascending: 'Rozwój',
    descending: 'Malejąco',
    value: 'Wartość',
    select_filter: 'Wybierz filtr',
    sort_vehicles: 'Zamów pojazdy',
    sort_for_alphanumeric: 'Alfabetyczny',
    sort_for_lastpoint: 'Chronologiczny',
    sort_for_company: 'Agencja',
    sort_for_activity: 'Działalność',
    company_select: 'Wybierz firmę',
    no_companies_founded: 'Nie znaleziono żadnych firm',
    multicompany: 'Filtruj według firmy',
    no_vehicles_founded: 'Nie znaleziono pojazdów',
    print: 'Naciskać',
    export: 'Eksport',
    print_recap: 'Wydrukuj podsumowanie',
    select_date: 'Wybierz datę',
    add: 'dodać',
    add_new: 'Dodaj nowe',
    delete: 'Usuwać',
    edit: 'Edytować',
    options: 'Opcje',
    login: 'Zaloguj sie',
    logout: 'Ty wyjdź',
    logout_confirm: 'Czy na pewno chcesz zrezygnować?',
    login_button: 'Zaloguj sie',
    connect: 'Łączyć',
    logged: 'Połączony',
    manage: 'Zarządzać',
    loading: 'Ładowanie',
    loading_timeout: 'Błąd sieci. Załaduj stronę ponownie lub spróbuj ponownie później',
    go_back: 'Wróć',
    select_coordinates: 'Wybierz współrzędne',
    other: 'Inny',
    options_general: 'Ogólny',
    options_language: 'Język',
    change_interface_language: 'Wybierz język interfejsu',
    change_infringment_language: 'Wybierz kraj naruszenia',
    change_timezone: 'Wybierz strefę czasową',
    save_language_settings: 'Zapisz ustawienia językowe',
    vehicle_list_options: 'Lista pojazdów',
    select_view: 'Wybierz widok',
    select_fields_placeholder: 'Wybierz pola do wyświetlenia',
    selected_field: 'Wybrane pole',
    selected_fields: 'Wybrane pola',
    list: 'Lista',
    table: 'Tabela',
    view_options: 'Zobacz opcje',
    fluid_table: 'Szerokość dynamiczna',
    fluid_table_descr: 'Ustaw płynną szerokość tabeli w oparciu o wybrane pola',
    vehicle_fields_list: 'Jakie informacje chcesz wyświetlać dla każdego pojazdu? (Maks. 3)',
    vehicle_fields_list_warning:
        'Uwaga: pole nie zostanie wyświetlone, jeśli nie jest dostępne dla pojazdu',
    vehicle_identification: 'Jak chcesz, aby pojazd był identyfikowany na liście głównej?',
    apply_to_marker: 'Zastosuj to ustawienie również do pojazdów na mapie',
    element_icon_section: 'Ikona elementu',
    element_title_section: 'Nazwa przedmiotu',
    element_fields_list_section: 'Lista pól elementów',
    element_icon_description: 'Wybierz ikonę, która będzie wyświetlana dla elementu',
    element_icon: 'Ikona',
    element_icon_color: 'Kolor ikony',
    options_map: 'Opcje mapy',
    vehicles_clustering: 'Grupowanie pojazdów',
    vehicles_clustering_warning: 'Aktualizacja tego ustawienia wymaga ponownego załadowania strony',
    options_marker: 'Opcje znaczników',
    size: 'Wymiar',
    font_size: 'Rozmiar czcionki',
    font_color: 'Kolor tekstu',
    background_color: 'Kolor tła',
    opacity: 'Przezroczystość',
    color: 'Kolor',
    small: 'Mały',
    medium: 'Średni',
    large: 'Świetnie',
    additional_info: 'Dodatkowe informacje',
    analysis_options: 'Analiza trasy',
    options_chart: 'Opcje wykresu',
    options_timeline: 'Opcje osi czasu',
    hide_timeline: 'Ukryj oś czasu',
    map: 'Mapa',
    silica: 'Krzemionka',
    google: 'Google',
    google_satellite: "Satelita Google'a",
    sandbox: 'Piaskownice',
    silkysand: 'Jedwabisty Piasek',
    gravelpit: 'Żwirownia',
    open_street_maps: 'Otwórz mapy ulic',
    arcgis_satellite: 'Satelita ArcGIS',
    traffic: 'Ruch drogowy',
    heavy_vehicles_ban: 'Zakazy pojazdów ciężkich',
    driver: 'Kierowca',
    no_driver: 'Brak kierowcy',
    drivers: 'Kierowcy',
    driver_card: 'Karta kierowcy',
    name: 'Imię',
    surname: 'Nazwisko',
    driver_activity: 'Aktywność kierowcy',
    driver_status: 'Stan kierowcy',
    activity_type: 'Rodzaj aktywności',
    activity: 'Działalność',
    no_activity: 'Brak aktywności',
    driver_at_rest: 'Kierowca w spoczynku',
    driver_available: 'Dostępny sterownik',
    driver_at_work: 'Kierowca w pracy',
    driver_driving: 'Prowadzenie kierowcy',
    inactive_vehicle: 'Pojazd nieaktywny',
    last_driver_detected: 'Wykryto ostatni sterownik',
    rest: 'Odpoczynek',
    work: 'Praca',
    driving: 'Przewodnik',
    availability: 'Dostępność',
    codriving: 'Jedź z załogą',
    codriver: 'Załoga',
    break: 'Wstrzymaj sesję',
    daily_rest: 'Codzienny odpoczynek',
    violation: 'Naruszenie',
    to_begin_within: 'Aby rozpocząć od',
    to_end_within: 'Do zakończenia',
    after_total_time_travel: 'Po podróży ogółem',
    after_total_distance_travel: 'Po przebyciu całkowitej odległości wynoszącej',
    residual_commitment_type: 'Rodzaj zobowiązania rezydualnego',
    residual_commitment: 'Resztkowe zaangażowanie',
    commitment_hours: 'Godziny zaangażowania',
    commitment_hours_description:
        'Znajdź wszystkie pojazdy, których kierowcy mają zobowiązanie rezydualne większe niż wskazane',
    driver_plafond: 'Sufit kierowcy',
    residual_driving: 'Wskazówki resztkowe',
    residual_bonuses: 'Pozostałe bonusy',
    consider_bonuses: 'Czas jest obliczany z uwzględnieniem pozostałych premii',
    current_activity: 'Aktualna działalność',
    next_activity: 'Następna aktywność',
    view_plafond_detail: 'Zobacz szczegóły sufitu',
    card: 'Papier',
    driver_change: 'Zmiana kierowcy',
    shared_links: 'Udostępnione linki',
    gf: 'WTEDY wykryto',
    analyze: 'Analizować',
    plan: 'Plan',
    vehicle: 'Pojazd',
    vehicles: 'Pojazdy',
    vehicle_info: 'Informacje o pojeździe',
    vehicle_type: 'Typ pojazdu',
    vehicle_body: 'Nadwozie pojazdu',
    truck_status: 'Stan pojazdu',
    brand: 'Marka',
    model: 'Model',
    no_card: 'Nie włożono papieru',
    no_card_driving: 'Jazda bez dokumentów',
    speed: 'Prędkość',
    tacho_speed: 'Prędkość (tachograf)',
    vehicle_hooked: 'Pojazd przymocowany',
    truck_hooked_text: 'Ten pojazd jest przymocowany do następnego przyczepy: ',
    trailer_hooked_text: 'Ten przyczep jest przymocowany do następnego pojazdu: ',
    no_driver_rfid_info: 'Karta kierowcy nie jest zarejestrowana w bazie kierowców',
    gps_speed: 'Prędkość (GPS)',
    level: 'Poziom',
    battery: 'bębny',
    low_battery_level: 'Niski poziom baterii',
    fuel: 'Paliwo',
    fuel_level: 'Poziom paliwa',
    low_fuel_level: 'Mało paliwa',
    consumption: 'Konsumpcja',
    consumptions: 'Konsumpcja',
    fuel_consumption: 'Zużycie paliwa',
    fuel_avg_consumption: 'Średnie dzienne zużycie paliwa',
    altitude: 'Wysokość',
    weight: 'Waga',
    lastpoint_weight: 'Waga (ostatni punkt)',
    vehicle_weight: 'Waga (t)',
    vehicle_length: 'Długość (m)',
    vehicle_width: 'Szerokość (m)',
    vehicle_height: 'Wysokość (m)',
    odometer: 'Drogomierz',
    odometer_tacho: 'Licznik kilometrów (tachograf)',
    odometer_gps: 'Licznik przebiegu (GPS)',
    gps_odometer: 'Licznik przebiegu (GPS)',
    last_point: 'Ostatni punkt',
    moving: 'W ruchu',
    stopped: 'Zatrzymany',
    engine_on: 'Silnik pracuje',
    ignition: 'Zapłon włączony',
    engine_off: 'Silnik wyłączony',
    engine_on_off: 'Uruchomienie silnika',
    ignition_on: 'Zapłon włączony',
    ignition_off: 'Zapłon wyłączony',
    device: 'Urządzenie',
    plate: 'Płyta',
    chassis: 'Podwozie',
    status: 'Status',
    custom_id: 'Nazwa niestandardowa',
    heading: 'Kierunek',
    rpm_engine: 'Obroty silnika',
    pto_active: 'WOM aktywny',
    pto_state: 'Stan WOM',
    device_timestamp: 'Znak czasu',
    info: 'Informacje',
    solar_day_report: 'Raport dnia kalendarzowego',
    telemetry: 'Telemetria',
    parameters: 'Parametry',
    engine_coolant_temp: 'Temperatura silnika',
    engine_rpm: 'Obroty silnika',
    dallas_temp_1: 'Temperatura 1',
    dallas_temp_2: 'Temperatura 2',
    dallas_temp_id_1: 'Identyfikator temperatury 1',
    dallas_temp_id_2: 'Identyfikator temperatury 2',
    digital_input_1: 'Wejście cyfrowe 1',
    digital_input_2: 'Wejście cyfrowe 2',
    digital_input_3: 'Wejście cyfrowe 3',
    digital_input_4: 'Wejście cyfrowe 4',
    digital_input_5: 'Wejście cyfrowe 5',
    battery_level: 'Poziom baterii',
    adblue_liters: 'Litry Adblue',
    adblue_level: 'Poziom AdBlue',
    gnss_status: 'Stan GNSS',
    gnss_status_values: [
        'Zużyty',
        'Włączony, brak GPS',
        'Włączone, brak satelitów',
        'Włączone, z satelitami',
        'Dalej, we śnie',
        'Stan chroniony'
    ],
    gnss_off: 'GNSS wyłączony',
    gnss_on_fix: 'Włączony GNSS, z satelitami',
    gnss_on_without_fix: 'Włączony GNSS, brak satelitów',
    gnss_on_sleep: 'GNSS włączony, w trybie uśpienia',
    gnss_on_protection: 'GNSS włączony, stan ochrony',
    gsm_signal: 'Sygnał GSM',
    tacho_source: 'Źródło tachografu',
    external_voltage: 'Dieta',
    tacho_overspeed: 'Tachograf przekroczenia prędkości',
    no_points_title: 'Ostatnia aktywność ponad 24 godziny temu',
    no_points: 'Ten pojazd nie wysłał żadnych punktów przez ponad 24 godziny',
    clutch: 'Sprzęgło',
    clutch_switch: 'Przełącznik sprzęgła',
    brake_pedal: 'Pedał hamulca',
    accelerator_pedal: 'Pedał gazu',
    pedal_released: 'Pedał zwolniony',
    pedal_pressed: 'Pedał wciśnięty',
    data_mode: 'Tryb danych',
    home_stop: 'Zablokowany na miejscu',
    home_moving: 'W ruchu na miejscu',
    roaming_stop: 'Utknąłem w roamingu',
    roaming_moving: 'W ruchu w roamingu',
    unknown_stop: 'Zatrzymanie nieznane',
    unknown_moving: 'Przeprowadzka nieznana',
    engine_coolant_temperature: 'Temperatura płynu chłodzącego',
    engine_temperature: 'Temperatura silnika',
    cruise_control_active: 'Tempomat aktywny',
    adblue_off: 'Adblue wyłączony',
    adblue_red: 'Adblue czerwony',
    adblue_yellow: 'Adblue żółty',
    adblue_info: 'Informacje o Adblue',
    charger_notconnected: 'Ładowarka nie jest podłączona',
    charger_connected: 'Ładowarka podłączona',
    tacho_unknown: 'Nieznany',
    tacho_kline: 'Linia K',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'Pojazd, do którego próbujesz uzyskać dostęp, jest niedostępny',
    vehicle_with_lock_commands: 'Pojazd ze sterowaniem blokującym',
    lock: 'Blok',
    unlock: 'Odblokować',
    send: 'Wysłać',
    command_sent: 'Polecenie zostało wysłane pomyślnie',
    turn_on: 'światło włączone',
    turned_on: 'Włączony',
    turn_off: 'Wyłączyć',
    turned_off: 'Zużyty',
    confirm_send_command: 'Czy na pewno chcesz wysłać polecenie?',
    temperature: {
        title: 'Sondy temperatury',
        probe: ['Sonda 1', 'Sonda 2', 'Sonda 3', 'Sonda 4', 'Sonda 5', 'Sonda 6']
    },
    no_power_supply: 'Brak mocy',
    no_gps: 'Brak GPS-u',
    tacho_clock_from_updated_error: 'Błąd synchronizacji zegara tachografu',
    tacho_clock_from_device_error: 'Błąd synchronizacji między tachografem a jednostką sterującą',
    device_timestamp_error: 'Błąd daty ECU',
    select_color_mode: 'Wybierz tryb koloru',
    light_mode: 'Tryb światła',
    dark_mode: 'Tryb ciemny',
    color_blind_mode: 'Tryb dla daltonistów',
    color_blind_dark_mode: 'Tryb ciemny dla daltonistów',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Polecane pojazdy',
            search_trucks_title: 'Szukaj pojazdów',
            search_trucks_tags_title: 'Powiązane tagi',
            search_trucks_status_title: 'Aktywność pojazdu',
            search_trucks_driver_status_title: 'Aktywność kierowcy',
            search_near_trucks_title: 'Znajdź pojazdy w pobliżu'
        },
        detailspanel: {
            bind_vehicle: 'Sparuj pojazd',
            add_new_tag: 'Dodaj jako nowy tag',
            select_new_tag: 'Wybierz lub wyszukaj tag',
            binded_tags: 'Powiązane tagi',
            binded_tag: 'Powiązany tag'
        },
        analyzer: {
            vehicle_activity_analisys: 'Analiza aktywności pojazdu',
            timeline_separator: 'Grupuj według',
            loading_error: 'Wystąpił błąd podczas ładowania danych'
        },
        search_resources: {
            title: 'Wyszukaj zasoby',
            description:
                'Znajdź stacje benzynowe, parkingi i dostępne obszary najbliżej Twojego pojazdu.'
        }
    },
    analisys: {
        title: 'Analiza trasy',
        select_telemetries: 'Wybierz dane telemetryczne do wyświetlenia w analizie trasy',
        select_telemetries_description: 'Można wybrać maksymalnie 4 telemetrie do wyświetlenia',
        chart_groups: {
            movement_data: 'Dane ruchu',
            engine_data: 'Dane silnika',
            vehicle_data: 'Dane pojazdu',
            sensors_data: 'Dane czujnika',
            driver_data: 'Dane kierowcy',
            fuel_data: 'Dane dotyczące paliwa'
        },
        no_data: 'Brak dostępnych danych',
        no_data_description:
            'Brak danych trasy do wyświetlenia dla tego pojazdu lub dla wybranego okresu',
        no_data_contact_support: 'Aby uzyskać więcej informacji, skontaktuj się z obsługą klienta'
    },
    planner: {
        user_config: 'Konfiguracje użytkownika',
        no_user_config: 'Brak konfiguracji użytkownika do wyświetlenia',
        freight_access: 'Dostęp do toreb bagażowych',
        vehicle_actual_position: 'Aktualna pozycja pojazdu',
        plan_route: 'Zaplanuj trasę',
        select_on_map: 'Wybierz punkt na mapie',
        select_on_map_option: 'Wybierz na mapie',
        fill_route_info: 'Wprowadź informacje o trasie',
        fill_resources: 'Wybierz punkt, który chcesz dodać jako punkt trasy',
        fill_waypoints: 'Wybierz etapy',
        load_breaks: 'Obciążenie spoczywa',
        load_alternative_routes: 'Załaduj alternatywne trasy',
        savedroutes: {
            my_routes: 'Moje trasy',
            saved_routes: 'Zapisane trasy',
            no_saved_routes: 'Nie zapisałeś jeszcze żadnych tras',
            success_saved_route: 'Twoja trasa została pomyślnie zapisana',
            error_saved_route: 'Wystąpił błąd podczas zapisywania trasy',
            success_deleted_route: 'Twoja trasa została pomyślnie usunięta',
            error_deleted_route: 'Wystąpił błąd podczas usuwania ścieżki'
        },
        resources: {
            area_search: 'Szukaj w promieniu',
            station_services: 'Włącznie z tymi usługami',
            parking_services: 'Usługi parkingowe',
            select_rating: 'Wybierz wynik',
            select_security: 'Wybierz bezpieczeństwo',
            empty_search: 'Wyszukiwanie nie znalazło żadnych elementów do wyświetlenia',
            delivery_nation: 'Kraj dostawy',
            fai_featured: 'Powiązana z Lumesią',
            self_service: 'Samoobsługa',
            served: 'Podawane',
            total_parking_spots: 'Łączna liczba miejsc',
            rating: 'Narzut',
            reviews: 'Opinie',
            security: 'Bezpieczeństwo',
            price_per_night: 'Cena za noc'
        },
        emissions_labels: 'Emisje',
        emissions: {
            fuelconsumption: 'Zużycie paliwa',
            hydrocarbons: 'Węglowodory',
            methane: 'Metan',
            hydrocarbonsexceptmethane: 'Węglowodory z wyjątkiem metanu',
            carbonmonoxide: 'Tlenek węgla',
            carbondioxide: 'Dwutlenek węgla',
            fossilcarbondioxide: 'Kopalny dwutlenek węgla',
            sulphurdioxide: 'Dwutlenek siarki',
            nitrogenoxides: 'Tlenki azotu',
            nitrogendioxide: 'Dwutlenek azotu',
            nitrousoxide: 'Podtlenek azotu',
            ammonia: 'Amoniak',
            benzene: 'Benzen',
            particles: 'Cząsteczki',
            numberofparticles: 'Liczba cząstek'
        },
        plafond_configuration: {
            title: 'Konfiguracja sufitu',
            driving_time_regulation: 'Czas podróży',
            last_time_the_driver_worked: 'Ostatnia aktywność kierowcy',
            eu_driving_time_regulation_for_single_day: 'Jednego dnia',
            eu_driving_time_regulation_for_single_long_day: 'Dzień pojedynczy (z bonusem)',
            eu_driving_time_regulation_for_multiple_day: 'Więcej dni',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Wiele dni (z załogą)',
            accumulated_driving_time_since_lastbreak: 'Godziny jazdy od ostatniej przerwy',
            accumulated_driving_time_since_lastdailyrest:
                'Liczba godzin jazdy od ostatniego dziennego odpoczynku',
            accumulated_travel_time_since_lastdailyrest:
                'Godziny podróży od ostatniego dziennego odpoczynku'
        },
        set_route: 'Ustaw trasę',
        select_profile: 'Wybierz profil',
        select_co2_class: 'Wybierz klasę emisji',
        class: 'Klasa',
        billed_roads: 'Drogi płatne',
        waypointevent: 'Zatrzymywać się',
        waypoints_label: 'Gradacja',
        add_waypoint: 'Dodaj scenę',
        delete_waypoint: 'Usuń etap',
        select_waypoint: 'Wybierz etap',
        select_resource: 'Wybierz punkt, który chcesz dodać jako punkt trasy',
        last_waypoint: 'Ostatni przystanek',
        waypoints_check: 'Są etapy do ukończenia',
        waypoints_required: 'Konieczne są co najmniej 2 kroki',
        countryevent: 'Państwo',
        tollevent: 'Punkt poboru opłat',
        event: 'Wydarzenie',
        alpega: 'Alpega',
        alpega_login: 'Zaloguj się do Alpegi',
        alpega_searches: 'Twoje badania',
        timocom: 'Timocom',
        pickup: 'Wycofać',
        delivery: 'Dostawa',
        extimated_cost: 'Szacowany koszt',
        calculate_route: 'Oblicz trasę',
        calculate_isocrona: 'Oblicz izochronę',
        complete_step_2: 'Wykonaj krok 2',
        reset_route: 'Nowa droga',
        route_error: 'Wystąpił błąd podczas obliczania trasy',
        edit_route: 'Edytuj trasę',
        save_route: 'Zapisz trasę',
        delete_route: 'Usuń trasę',
        route_name: 'Nazwa ścieżki',
        override_route: 'Zastąp ścieżkę',
        save_as_new_route: 'Zapisz nową trasę',
        calculate_route_for: 'Oblicz trasę dla',
        ptvexport: 'Eksportuj do PTV Navigator',
        isocrona: 'Dostępne obszary',
        reset_isocrona: 'Zresetuj dostępne obszary',
        search_location: 'Szukaj lokalizacji',
        search_station: 'Wyszukaj dystrybutora',
        search_parking: 'Szukaj parkingu',
        search_poi: 'Wyszukaj POI',
        include_plafond: 'Uwzględnij limit kierowcy przy obliczaniu trasy',
        include_plafond_near_vehicles:
            'Znajdź pojazdy z kierowcami, którzy mają wystarczające zaangażowanie, aby dotrzeć do szukanej lokalizacji',
        violated_plafond: 'Wybrana trasa narusza limit kierowcy'
    },
    sharedlinks: {
        back_to_links: 'Wróć do linków',
        create_link: 'Twórz linki',
        update_link: 'Aktualizuj link',
        edit_link: 'Edytuj link',
        update_link_expire_date: 'Zaktualizuj datę ważności',
        delete_link: 'Usuń link',
        delete_link_confirm: 'Usunąć wybrane łącze?',
        all_your_shared_links: 'Wszystkie udostępnione linki',
        no_shared_links: 'Nie udostępniłeś jeszcze żadnych pojazdów',
        vehicles_link: 'Linki do pojazdów',
        select_share_vehicles: 'Wybierz pojazdy do udostępnienia',
        select_start_expire_date: 'Wybierz datę rozpoczęcia i wygaśnięcia',
        created_link_error: 'Błąd podczas tworzenia linku',
        created_link_success: 'Link został utworzony pomyślnie',
        updated_link_error: 'Błąd aktualizacji linku',
        updated_link_success: 'Link został pomyślnie zaktualizowany',
        service_description: 'Udostępnij ten link, aby wyświetlić śledzenie wybranych pojazdów',
        link_preview: 'Podgląd linku',
        share_single_vehicle: 'Udostępnij lokalizację tego pojazdu',
        track_vehicle_position: 'Śledź lokalizację wybranych pojazdów',
        start_date_warning: 'Nie ma możliwości zmiany daty rozpoczęcia',
        start_date_condition: 'Jeżeli chcesz zmienić datę rozpoczęcia musisz utworzyć nowy link',
        expire_date_description:
            'Wskazuje datę wygaśnięcia, po upływie której łącze nie będzie już dostępne',
        start_expire_date_description:
            'Podaj początkową datę, od której łącze będzie dostępne, oraz datę wygaśnięcia, po której łącze nie będzie już dostępne:'
    },
    viewsharedlink: {
        invalid_date_range: 'Wybrany zakres dat jest nieprawidłowy dla tego linku',
        too_early_link: 'Link, do którego próbujesz uzyskać dostęp, nie jest jeszcze dostępny',
        too_late_link: 'Link, do którego próbujesz uzyskać dostęp, już nie istnieje',
        show_route: 'Pokaż trasę'
    },
    tags: {
        manage_tags: 'Zarządzaj tagami',
        add_tag: 'Dodaj tagi',
        add_tag_placeholder: 'Wpisz nazwę tagu',
        add_tag_error: 'Błąd podczas dodawania tagu',
        add_tag_success: 'Tag został pomyślnie dodany',
        delete_tag: 'Usuń tagi',
        delete_tag_confirm: 'Usunąć wybrany tag?',
        delete_tag_error: 'Błąd podczas usuwania tagu',
        delete_tag_success: 'Tag pomyślnie usunięty',
        update_tag: 'Aktualizuj tagi',
        update_tag_error: 'Błąd podczas aktualizowania tagu',
        update_tag_success: 'Tag został pomyślnie zaktualizowany',
        all_your_tags: 'Wszystkie Twoje tagi',
        no_tags: 'Nie utworzyłeś jeszcze żadnych tagów',
        tag_name: 'Nazwa znacznika'
    },
    manage_poi: {
        title: 'Zarządzaj punktami POI',
        your_pois: 'Twoje punkty POI',
        add_poi: 'Dodaj POI',
        no_pois: 'Nie utworzyłeś jeszcze żadnych punktów POI',
        fields: {
            name: 'Imię',
            type: 'Facet',
            color: 'Kolor',
            note: 'Notatka',
            address: 'Wyszukaj adres',
            latitude: 'Szerokość',
            longitude: 'Długość geograficzna',
            radius: 'Promień wyszukiwania',
            radius_description: 'Aby włączyć geofencing, ustaw wartość większą niż zero'
        },
        types_poi: {
            all: 'Wszyscy',
            headquarter: 'Strona',
            truck_washes: 'Myjnie samochodów ciężarowych',
            blacklist: 'Czarna lista',
            end_of_the_line: 'Stacja końcowa',
            customers: 'Klienci',
            stops: 'Zatrzymywać się',
            suppliers: 'Dostawcy',
            interports: 'Interporty',
            markets: 'Rynki',
            large_square: 'Kwadraty',
            poi_distributors: 'WTEDY dystrybutorzy',
            poi_workshops: 'WTEDY warsztaty',
            harbors: 'Porty',
            restaurants: 'Restauracje',
            stations: 'Stacje'
        },
        messages: {
            add_poi_error: 'Błąd podczas dodawania POI',
            add_poi_success: 'WTEDY dodano pomyślnie',
            update_poi_error: 'Błąd aktualizacji POI',
            update_poi_success: 'WTEDY zaktualizowano pomyślnie',
            delete_poi_error: 'Błąd podczas usuwania POI',
            delete_poi_success: 'WTEDY pomyślnie usunięto'
        }
    },
    vehicles_cross: {
        title: 'Przejazd pojazdu',
        fields: {
            address: 'Adres',
            radius: 'promień',
            time_interval: 'Upływ czasu',
            time_interval_description: 'Wybierz maksymalny zakres czasu wynoszący 24 godziny'
        },
        messages: {
            max_time_interval: 'Maksymalny zakres czasu to 24 godziny',
            error_searching: 'Wystąpił błąd podczas wyszukiwania pojazdów',
            searching: 'Badania w toku...',
            long_loading: 'Wyszukiwanie może zająć kilka minut, proszę czekać...',
            no_vehicles_cross: 'Nie znaleziono pojazdów'
        }
    },
    plafonds: {
        session: 'Przerwa sesyjna',
        break: 'Przerwa',
        drive_occurring: 'Trwa Okres Jazdy:',
        drive_max_label: 'Maksymalny czas jazdy',
        drive_max_value: '4h 30m',
        drive_done: 'Wykonana jazda',
        session_title: 'Informacje o Przerwie Podzielonej',
        session_tooltip: 'Możliwe jest zrobienie Przerwy Podzielonej',
        session_done: 'Wykryto przerwę trwającą co najmniej 15 minut.',
        session_info: 'Pierwsza część Przerwy Podzielonej już wykonana',
        session_descr:
            "Przypominamy, że zgodnie z obowiązującymi przepisami można zrobić przerwę 15'+30' lub 15'+15'+15' (w zależności od rodzaju wykonywanego transportu).",
        session_choice: 'Możliwe jest zrobienie co najmniej <b>30m</b> w ciągu następnych',
        brake_start: 'Początek przerwy',
        brake_done: 'Przerwa wykonana',
        daily: 'Codzienna',
        weekly: 'Tygodniowa',
        driver_in_drive: 'Przejeżdżasz obok',
        driver_in_rest: 'Pozostały odpoczynek',
        driver_in_work: 'Praca',
        driver_in_available: 'Dostępność',
        driver_in_availability: 'Dostępność',
        current_activity: 'Aktualna działalność',
        mini_plafond: 'Minisufit',
        last_update: 'Ostatnia aktualizacja',
        panel_title: 'Panel sufitowy',
        residual_commitment: 'Resztkowe zaangażowanie',
        daily_driving: 'Codzienny przewodnik',
        weekly_guide: 'Przewodnik tygodniowy',
        biweekly_guide: 'Dwutygodniowy przewodnik',
        last_rest: 'Ostatni tygodniowy odpoczynek wykorzystany',
        minimal_duration_next: 'Następny minimalny czas trwania',
        top_start_until: 'Aby rozpocząć od',
        reduced_weekly_rest: 'W ramach rekompensaty skrócone tygodniowe okresy odpoczynku',
        time_to_compensate: 'Czas to zrekompensować',
        time_to_compensate_until: 'Aby zostać zrekompensowanym przez',
        footer_message: '* czas liczony jest z uwzględnieniem premii pozostałych',
        driver_in_block: 'Limit kierowców wyczerpany',
        résidu_commitment: 'Resztkowe zaangażowanie',
        week_guide: 'Przewodnik Hebdomadaire’a',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'Błąd 403',
            description:
                'Szanowny kliencie, informujemy, że Twoje konto nie jest obecnie skonfigurowane do dostępu do tej usługi. Aby otrzymać informacje na temat samej usługi',
            retry_login: 'Proszę spróbować zalogować się ponownie',
            contact_us: 'Skontaktuj się z nami'
        }
    },
    ptv: {
        maxpermittedweight: 'Waga ciężka',
        minpermittedweight: 'Minimalna waga',
        zonename: 'Obszar',
        unauthorizedtype: 'Nieautoryzowany kod',
        maxemissionstandardeu: 'Klasa emisji',
        fueltype: 'Typ paliwa',
        loadtype: 'Rodzaj transportu',
        opening: 'Jeździć',
        maxheight: 'maksymalna wysokość',
        maxweight: 'Waga ciężka',
        maxwidth: 'Maksymalna szerokość',
        maxlength: 'Maksymalna długość',
        absolutespeed: 'Prędkość',
        hazardoustowaters: 'Zakaz ruchu pojazdów przewożących towary niebezpieczne dla wody',
        hazardousgoods: 'Zakaz ruchu pojazdów przewożących towary niebezpieczne',
        combustibles: 'Zakaz pojazdów przewożących paliwo',
        maxaxleload: 'Maksymalny ciężar na osiach',
        totalpermittedweight: 'Waga ciężka',
        tunnelrestriction: 'Kod ograniczeń tunelowania',
        kingpintorearaxle: 'Odległość od sworznia królewskiego do osi',
        numberofaxles: 'Liczba osi',
        length: 'Ogon',
        delay: 'Opóźnienie',
        message: {
            text: 'Wiadomość'
        }
    },
    options_interface: 'Interfejs opcji'
}
