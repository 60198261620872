<template>
    <div
        class="d-head"
        :class="$mq == 'xs' || $mq == 'sm' ? 'details-mobile-header' : 'details-desktop-header'"
    >
        <!-- HEADER MOBILE -->
        <mq-layout :mq="['xs', 'sm']">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-2">
                        <div class="circle-icon big" :style="{ 'background-color': colorStatus }">
                            <truck-icon
                                style="padding: 5px; color: #fff; font-size: 34px"
                                :category="truck.category"
                            />
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="plate">
                            {{ identification }}
                        </div>
                        <div class="company text-muted">
                            {{
                                truck.details.secondary_company
                                    ? storeCompanies[truck.details.secondary_company]
                                    : storeCompanies[truck.details.company]
                            }}
                        </div>

                        <!-- Lastpoint coordinates -->
                        <div
                            class="p-coords text-dark-blue"
                            @click="copyCoordinates"
                            style="cursor: pointer"
                        >
                            <span>{{ truck.lastpoint.lat }}, </span>
                            <span>{{ truck.lastpoint.lng }}</span>

                            <input
                                type="text"
                                class="d-none"
                                ref="copyCoordinates"
                                v-model="coordinates"
                            />
                        </div>
                    </div>
                </div>

                <hr class="mb-0" />
            </div>
        </mq-layout>

        <!-- HEADER DESKTOP -->
        <mq-layout mq="md+">
            <div class="container-fluid" :class="collapsed ? 'g-0' : ''">
                <div class="targa-f" v-if="!collapsed && !tiny">
                    {{ identification }}
                </div>
                <div class="mb-3" v-if="truck?.details?.company && !tiny">
                    {{
                        truck.details.secondary_company
                            ? storeCompanies[truck.details.secondaty_company]
                            : storeCompanies[truck.details.company]
                    }}
                </div>
                <div :class="!collapsed ? 'row' : ''">
                    <div
                        class="col-3 d-flex flex-column justify-content-center align-items-center"
                        v-if="!collapsed"
                    >
                        <div class="circle-icon big" :style="{ 'background-color': colorStatus }">
                            <truck-icon
                                style="padding: 5px; color: #fff; font-size: 34px"
                                :category="truck.category"
                            />
                        </div>
                        <div
                            class="oval-status d-flex align-items-center justify-content-center d-none"
                            :style="{ 'background-color': colorStatus }"
                        >
                            <span class="status-text">{{ status }}</span>
                        </div>
                        <div v-if="truck.details.custom_id && !tiny" class="plate">
                            <small>{{ truck.plate }}</small>
                        </div>
                    </div>
                    <div class="collapsed-header" v-else>
                        <div
                            style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                            "
                        >
                            <div
                                class="circle-icon"
                                :style="{
                                    'background-color': colorStatus,
                                    'background-image': 'none'
                                }"
                            >
                                <truck-icon
                                    style="padding: 5px; color: #fff"
                                    :category="truck.category"
                                />
                            </div>
                            <span class="targa-c">{{
                                (truck.details.custom_id || truck.plate) | truncate(7)
                            }}</span>
                        </div>
                    </div>

                    <div :class="collapsed ? 'text-center' : 'col-9 d-flex flex-column'">
                        <div class="targa-f fleat-start" v-if="tiny">
                            {{ truck.details.custom_id || truck.plate }}
                        </div>

                        <div v-if="truck.details.custom_id && tiny" class="plate">
                            <small>{{ truck.plate }}</small>
                        </div>
                        <span
                            class="dati-autisti"
                            v-show="truck.type !== 3 || this.truck.lastpoint.rfid"
                        >
                            {{ driver }}
                            <i v-if="truck.lastpoint.rfid" class="fa-solid fa-id-badge"></i>
                        </span>
                        <div v-if="!tiny" class="lastpoint-info">
                            <!-- Lastpoint data -->
                            <div>
                                {{ truck.lastpoint.date | formatDate('UTC') }}
                            </div>

                            <!-- Lastpoint provenience -->
                            <div
                                v-if="
                                    truck.lastpoint.provenience && truck.lastpoint.provenience.web
                                "
                                class="p-provdescr"
                            >
                                <i
                                    v-if="
                                        truck.lastpoint.provenience &&
                                        truck.lastpoint.provenience.icon
                                    "
                                    :class="['fa-solid', truck.lastpoint.provenience.icon]"
                                ></i>
                                {{ truck.lastpoint.provenience.web }}
                            </div>

                            <!-- Lastpoint coordinates -->
                            <div
                                class="p-coords text-dark-blue"
                                @click="copyCoordinates"
                                style="cursor: pointer"
                            >
                                <span>{{ truck.lastpoint.lat }}, </span>
                                <span>{{ truck.lastpoint.lng }}</span>

                                <input
                                    type="text"
                                    class="d-none"
                                    ref="copyCoordinates"
                                    v-model="coordinates"
                                />
                            </div>
                        </div>

                        <div v-if="!tiny">
                            <span class="localizzazione-f">
                                <reverse-geocoding
                                    :full="true"
                                    :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]"
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    class="close-btn"
                    @click="$emit('close', truck)"
                    v-if="!tiny && $mq != 'xs' && $mq != 'sm'"
                >
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
        </mq-layout>
    </div>
</template>

<script>
import { toRefs } from 'vue'
import { useVehicleIdentification } from '@/composables/useVehicleIdentification'
import { mapState } from 'vuex'
import copy from 'copy-to-clipboard'

export default {
    name: 'DetailsHead',
    props: {
        truck: {
            type: Object,
            required: true
        },
        tiny: {
            type: Boolean,
            default: false
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        sharedView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { truck } = toRefs(props)
        const { identification } = useVehicleIdentification(truck)

        return {
            identification
        }
    },
    data() {
        return {
            coordinates: null
        }
    },
    computed: {
        status() {
            return ''
        },
        colorStatus() {
            if (this.moment().diff(this.moment(this.truck.lastpoint.date, 'UTC'), 'hours') > 1) {
                return 'var(--default-color)'
            } else {
                if (this.truck.activity_type == 'moving') return 'var(--moving-color)'
                else if (this.truck.activity_type == 'engine_on') return 'var(--engine-on-color)'
                else if (this.truck.activity_type == 'ignition') return 'var(--ignition-color)'
                else {
                    if (this.truck.lastpoint.ignition || this.truck.lastpoint.ignition > 0) {
                        return 'var(--ignition-color)'
                    } else {
                        return 'var(--default-color)'
                    }
                }
            }
        },
        driver() {
            let driver = this.truck.lastpoint.driver
            let rfid = this.truck.lastpoint.rfid

            let infos = null

            if (rfid) {
                if (rfid.driver) {
                    if (rfid.driver.name && rfid.driver.surname) {
                        infos = rfid.driver.name + ' ' + rfid.driver.surname
                    } else {
                        infos = 'N.A.'
                    }
                } else {
                    infos = 'N.A.'
                }
            }

            if (driver && driver.id > 1) {
                if (driver.name && driver.surname) {
                    infos = driver.name + ' ' + driver.surname
                } else {
                    infos = driver.card
                }
            }

            return infos || '- ' + this.__('no_card') + ' -'
        },
        ...mapState({
            storeCompanies: (state) => state.geoFleet.companies
        })
    },
    methods: {
        copyCoordinates() {
            copy(this.coordinates)

            this.$snotify.success(this.__('copied_coordinates'), { position: 'centerTop' })
        }
    },
    watch: {
        truck: {
            handler() {
                this.coordinates = `${this.truck.lastpoint.lat}, ${this.truck.lastpoint.lng}`
            },
            deep: true
        }
    },
    created() {
        if (this.truck)
            this.coordinates = `${this.truck.lastpoint.lat}, ${this.truck.lastpoint.lng}`
    }
}
</script>

<style scoped lang="scss">
@import './styles/DetailsHead.scss';
</style>
