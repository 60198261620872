export default {
    open: 'Megnyit',
    close: 'Bezárás',
    apply: 'Alkalmaz',
    cancel: 'Megszünteti',
    search: 'Közel',
    cancel_search: 'Keresés megszakítása',
    edit_search: 'Keresés szerkesztése',
    erase: 'Megszünteti',
    back_to_results: 'Menjen vissza az eredményekhez',
    save: 'Megment',
    retry: 'Próbáld újra',
    click_here: 'Kattints ide',
    copy: 'Másolat',
    copied: 'Másolva',
    copied_coordinates: 'Koordináták másolva',
    select: 'Válassza ki',
    selected: 'Kiválasztott',
    selected_plural: 'Kiválasztott',
    deselect: 'Törölje a kijelölést',
    select_multi_vehicles: 'Válasszon ki egy vagy több járművet',
    include: 'Tartalmazza',
    exclude: 'Kizárás',
    balanced: 'Kiegyensúlyozott',
    input: 'Bejárat',
    output: 'Kijárat',
    verified: 'Ellenőrzött',
    empty_search: 'Nincs találat',
    read_more: 'Olvass tovább',
    details: 'Részletek',
    actual: 'Jelenlegi',
    residual_time: 'Hátralévő idő',
    next: 'Következő',
    previous: 'Előző',
    menu_open: 'Menü megnyitása',
    hide: 'Elrejt',
    show: 'Előadás',
    none: 'Senki',
    none_alt: 'Egyik sem',
    all: 'Mindenki',
    all_alt: 'Minden',
    default: 'Alapértelmezett',
    settings: 'Beállítások',
    reset_settings: 'Beállítások alaphelyzetbe állítása',
    reset_settings_success: 'A beállítások visszaállítása sikeres volt',
    multiselect_default_value: {
        singular: 'Kiválasztott érték:',
        plural: 'Kiválasztott értékek:'
    },
    available: 'Elérhető',
    unavailable: 'Nem elérhető',
    not_available: 'Nem elérhető',
    not_available_plural: 'Nem elérhető',
    from: 'tól',
    to: 'hoz',
    of: 'Nak,-nek',
    yes: 'Igen',
    no: 'Nem',
    minutes: 'Percek',
    hours: 'Órák',
    hour: 'Most',
    days: 'Napok',
    day: 'Nap',
    months: 'Hónapok',
    month: 'Hónap',
    years: 'Évek',
    year: 'Év',
    days_names: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    days_names_short: ['Nap', 'Hétfő', 'márc', 'Házasodik', 'Cs', 'Péntek', 'Ült'],
    months_names: [
        'január',
        'február',
        'március',
        'április',
        'Lehet',
        'június',
        'július',
        'augusztus',
        'szeptember',
        'október',
        'november',
        'december'
    ],
    months_names_short: [
        'Gen',
        'Február',
        'márc',
        'Április',
        'Mag',
        'Lent',
        'Július',
        'Augusztus',
        'Készlet',
        'Október',
        'November',
        'December'
    ],
    areas: 'Területek',
    area: 'Terület',
    time: 'Idő (perc)',
    time_label: 'Idő',
    cost: 'Költség',
    toll_cost: 'Útdíj költség',
    km_cost: 'Költség kilométerenként',
    hourly_cost: 'Óraköltség',
    working_cost: 'Teljes emberköltség',
    total_cost: 'Összköltsége',
    expire_date: 'Lejárati dátum',
    start_date: 'Kezdő dátum',
    recap: 'Összegzés',
    start: 'Rajt',
    starting_point: 'Indulás',
    end: 'vége',
    finish_point: 'megérkeztem',
    duration: 'Időtartam',
    distance: 'Távolság',
    daily_distance: 'Naponta megtett km',
    distance_total: 'Teljes távolság',
    gps_distance: 'Távolság (GPS)',
    distance_gps: 'Napi megtett km (GPS)',
    pause: 'Szünet',
    segment: 'Azzal számol',
    start_time: 'Kezdési idő',
    end_time: 'Idő vége',
    service_time: 'Szolgálati idő',
    waypoint_load: 'Rakodás a fedélzetre',
    state: 'Állapot',
    violated_plafond: 'A mennyezet megsértése',
    traffic_delay: 'Forgalom késés',
    company: 'Ügynökség',
    address: 'Cím',
    position: 'Pozíció',
    route: 'Pálya',
    filter_date: 'Szűrés dátum szerint',
    warning: 'Figyelem',
    no_warning: 'Nincsenek figyelmeztetések',
    alerts: 'Megjegyzések',
    trucks_with_alerts: 'Figyelmeztetéssel ellátott járművek',
    bluetooth_devices: 'Bluetooth eszközök',
    no_bluetooth_devices: 'Nem található Bluetooth-eszköz',
    connected: 'csatlakoztatva',
    disconnected: 'Szétkapcsolt',
    bluetooth_types: {
        temperature_probe: 'Hőmérséklet szonda',
        door_probe: 'Ajtó és hőmérséklet szonda'
    },
    back_to_home: 'Vissza a főoldalra',
    cerca: 'Közel',
    search_vehicle: 'Jármű keresése',
    filter: 'Szűrő',
    filter_label: 'Szűrő',
    filters_label: 'Szűrők',
    filter_by: 'Szűrés vlami alapján',
    operations: 'Tevékenységek',
    filter_vehicles: 'Szűrő járművek',
    filter_applied: 'A szűrő sikeresen alkalmazva',
    filter_canceled: 'A szűrő sikeresen törölve',
    order_by: 'Rendezés',
    order: 'Rendelés',
    ascending: 'Növekvő',
    descending: 'Csökkenő',
    value: 'Érték',
    select_filter: 'Szűrő kiválasztása',
    sort_vehicles: 'Rendeljen járműveket',
    sort_for_alphanumeric: 'Betűrendes',
    sort_for_lastpoint: 'Időrendi',
    sort_for_company: 'Ügynökség',
    sort_for_activity: 'Tevékenység',
    company_select: 'Válasszon céget',
    no_companies_founded: 'Nem található cég',
    multicompany: 'Szűrés cég szerint',
    no_vehicles_founded: 'Nem találhatók járművek',
    print: 'nyomja meg',
    export: 'Export',
    print_recap: 'Összefoglaló nyomtatása',
    select_date: 'Válasszon egy dátumot',
    add: 'add hozzá',
    add_new: 'Új hozzáadása',
    delete: 'Töröl',
    edit: 'Szerkesztés',
    options: 'Lehetőségek',
    login: 'Belépés',
    logout: 'Kimész',
    logout_confirm: 'Biztosan ki szeretne lépni?',
    login_button: 'Belépés',
    connect: 'Csatlakozás',
    logged: 'csatlakoztatva',
    manage: 'Kezelése',
    loading: 'Betöltés',
    loading_timeout: 'Hálózati hiba. Kérjük, töltse be újra az oldalt, vagy próbálja újra később',
    go_back: 'Gyere vissza',
    select_coordinates: 'Válassza ki a koordinátákat',
    other: 'Egyéb',
    options_general: 'Tábornok',
    options_language: 'Nyelv',
    change_interface_language: 'Válassza ki a felület nyelvét',
    change_infringment_language: 'Válassza ki a jogsértés országát',
    change_timezone: 'Válassza ki az időzónát',
    save_language_settings: 'Mentse el a nyelvi beállításokat',
    vehicle_list_options: 'Járműlista',
    select_view: 'Válassza ki a nézetet',
    select_fields_placeholder: 'Válassza ki a megtekinteni kívánt mezőket',
    selected_field: 'Kiválasztott mező',
    selected_fields: 'Kijelölt mezők',
    list: 'Lista',
    table: 'asztal',
    view_options: 'Opciók megtekintése',
    fluid_table: 'Dinamikus szélesség',
    fluid_table_descr: 'Tegye gördülékenysé a táblázat szélességét a kiválasztott mezők alapján',
    vehicle_fields_list:
        'Milyen információkat szeretne megjeleníteni az egyes járműveknél? (Maximum 3)',
    vehicle_fields_list_warning:
        'Figyelem: a mező nem jelenik meg, ha nem áll rendelkezésre a jármű számára',
    vehicle_identification: 'Hogyan szeretné azonosítani a járművet a fő listán?',
    apply_to_marker: 'Alkalmazza ezt a beállítást a térképen szereplő járművekre is',
    element_icon_section: 'Elem ikonra',
    element_title_section: 'Elem címe',
    element_fields_list_section: 'Elemmezők listája',
    element_icon_description: 'Válassza ki az elemhez megjeleníteni kívánt ikont',
    element_icon: 'Ikon',
    element_icon_color: 'Ikon színe',
    options_map: 'Térkép opciók',
    vehicles_clustering: 'Járművek csoportosítása',
    vehicles_clustering_warning: 'A beállítás frissítéséhez az oldal újratöltése szükséges',
    options_marker: 'Marker opciók',
    size: 'Dimenzió',
    font_size: 'Szövegméret',
    font_color: 'Szöveg szín',
    background_color: 'Háttérszín',
    opacity: 'Átláthatóság',
    color: 'Szín',
    small: 'Kicsi',
    medium: 'Közepes',
    large: 'Nagy',
    additional_info: 'további információ',
    analysis_options: 'Útvonalelemzés',
    options_chart: 'Diagram opciók',
    options_timeline: 'Idővonal opciók',
    hide_timeline: 'Idővonal elrejtése',
    map: 'Térkép',
    silica: 'Szilícium-dioxid',
    google: 'Google',
    google_satellite: 'Google Satellite',
    sandbox: 'Homokozók',
    silkysand: 'Silkysand',
    gravelpit: 'Kavicsbánya',
    open_street_maps: 'Nyissa meg az Utcatérképeket',
    arcgis_satellite: 'ArcGIS Satellite',
    traffic: 'Forgalom',
    heavy_vehicles_ban: 'Nehézjárművek betiltása',
    driver: 'Sofőr',
    no_driver: 'Nincs sofőr',
    drivers: 'Drivers',
    driver_card: 'Vezetői kártya',
    name: 'Keresztnév',
    surname: 'Vezetéknév',
    driver_activity: 'A sofőr tevékenysége',
    driver_status: 'Illesztőprogram állapota',
    activity_type: 'Egyfajta tevékenység',
    activity: 'Tevékenység',
    no_activity: 'Nincs aktivitás',
    driver_at_rest: 'A sofőr nyugalomban van',
    driver_available: 'Driver elérhető',
    driver_at_work: 'Sofőr a munkahelyén',
    driver_driving: 'Sofőr vezetés',
    inactive_vehicle: 'A jármű inaktív',
    last_driver_detected: 'Utolsó illesztőprogram észlelve',
    rest: 'Pihenés',
    work: 'Munka',
    driving: 'Útmutató',
    availability: 'Elérhetőség',
    codriving: 'Vezess legénységgel',
    codriver: 'Legénység',
    break: 'Munkamenet szüneteltetése',
    daily_rest: 'Napi pihenő',
    violation: 'Szabálysértés',
    to_begin_within: 'Kezdje',
    to_end_within: 'Be kell fejezni',
    after_total_time_travel: 'Egy utazás után összesen',
    after_total_distance_travel: 'A teljes megtett távolság után',
    residual_commitment_type: 'Fennmaradó kötelezettségvállalás típusa',
    residual_commitment: 'Maradék elkötelezettség',
    commitment_hours: 'Óráknyi elkötelezettség',
    commitment_hours_description:
        'Keresse meg az összes olyan járművet, amelynek vezetőinek fennmaradó kötelezettsége nagyobb, mint a jelzett',
    driver_plafond: 'Vezető mennyezet',
    residual_driving: 'Maradék útmutatás',
    residual_bonuses: 'Maradék bónuszok',
    consider_bonuses: 'Az idő kiszámítása a fennmaradó bónuszok figyelembevételével történik',
    current_activity: 'Jelenlegi tevékenység',
    next_activity: 'Következő tevékenység',
    view_plafond_detail: 'Tekintse meg a mennyezet részleteit',
    card: 'Papír',
    driver_change: 'Sofőr csere',
    shared_links: 'Megosztott linkek',
    gf: 'AKKOR észlelve',
    analyze: 'Elemezze',
    plan: 'Terv',
    vehicle: 'Jármű',
    vehicles: 'Járművek',
    vehicle_info: 'Jármű információk',
    vehicle_type: 'Jármű típus',
    vehicle_body: 'Jármű karosszéria',
    truck_status: 'A jármű állapota',
    brand: 'Márka',
    model: 'Modell',
    no_card: 'Papír nincs behelyezve',
    no_card_driving: 'Papírmentes vezetés',
    speed: 'Sebesség',
    tacho_speed: 'Sebesség (tachográf)',
    vehicle_hooked: 'Jármű csatlakoztatva',
    truck_hooked_text: 'Ez a jármű csatlakoztatva a következő csuklóhoz: ',
    trailer_hooked_text: 'Ez a csukló csatlakoztatva a következő járműhöz: ',
    no_driver_rfid_info: 'A vezetői kártya nem szerepel a vezetői listában',
    gps_speed: 'Sebesség (GPS)',
    level: 'Szint',
    battery: 'Dobok',
    low_battery_level: 'Alacsony akkumulátor töltöttségi szint',
    fuel: 'Üzemanyag',
    fuel_level: 'Benzin szint',
    low_fuel_level: 'Alacsony üzemanyagszint',
    consumption: 'Fogyasztás',
    consumptions: 'Fogyasztás',
    fuel_consumption: 'Üzemanyag fogyasztás',
    fuel_avg_consumption: 'Átlagos napi üzemanyag-fogyasztás',
    altitude: 'Magasság',
    weight: 'Súly',
    lastpoint_weight: 'Súly (utolsó pont)',
    vehicle_weight: 'Súly (t)',
    vehicle_length: 'Hossz (m)',
    vehicle_width: 'szélesség (m)',
    vehicle_height: 'Magasság (m)',
    odometer: 'Úthosszmérő',
    odometer_tacho: 'Kilométerszámláló (tachográf)',
    odometer_gps: 'Kilométerszámláló (GPS)',
    gps_odometer: 'Kilométerszámláló (GPS)',
    last_point: 'Utolsó pont',
    moving: 'Mozgásban',
    stopped: 'Megállt',
    engine_on: 'Motor jár',
    ignition: 'Gyújtás be',
    engine_off: 'Motor le',
    engine_on_off: 'A motor beindítása',
    ignition_on: 'Gyújtás be',
    ignition_off: 'Gyújtás kikapcsolva',
    device: 'Eszköz',
    plate: 'Lemez',
    chassis: 'Alváz',
    status: 'Állapot',
    custom_id: 'Egyéni név',
    heading: 'Irány',
    rpm_engine: 'Motor fordulatszám',
    pto_active: 'TLT aktív',
    pto_state: 'TLT állapota',
    device_timestamp: 'Időbélyeg',
    info: 'Info',
    solar_day_report: 'Naptári nap jelentés',
    telemetry: 'Telemetria',
    parameters: 'Paraméterek',
    engine_coolant_temp: 'Motor hőmérséklet',
    engine_rpm: 'Motor fordulatszám',
    dallas_temp_1: 'Hőmérséklet 1',
    dallas_temp_2: 'Hőmérséklet 2',
    dallas_temp_id_1: 'Hőmérséklet azonosító 1',
    dallas_temp_id_2: 'Hőmérséklet azonosító 2',
    digital_input_1: 'Digitális bemenet 1',
    digital_input_2: 'Digitális bemenet 2',
    digital_input_3: 'Digitális bemenet 3',
    digital_input_4: 'Digitális bemenet 4',
    digital_input_5: 'Digitális bemenet 5',
    battery_level: 'Az akkumulátor töltöttségi szintje',
    adblue_liters: 'Liter Adblue',
    adblue_level: 'Adblue szint',
    gnss_status: 'GNSS állapot',
    gnss_status_values: [
        'Elhasznált',
        'Bekapcsolva, nincs GPS',
        'Be, nincsenek műholdak',
        'Be, műholdakkal',
        'Be, alvás közben',
        'Védett állapot'
    ],
    gnss_off: 'GNSS kikapcsolva',
    gnss_on_fix: 'GNSS bekapcsolva, műholdakkal',
    gnss_on_without_fix: 'GNSS bekapcsolva, nincs műhold',
    gnss_on_sleep: 'GNSS bekapcsolva, alvó állapotban',
    gnss_on_protection: 'GNSS bekapcsolva, védelmi állapot',
    gsm_signal: 'GSM jel',
    tacho_source: 'Tachográf forrás',
    external_voltage: 'Diéta',
    tacho_overspeed: 'Túlsebességű tachográf',
    no_points_title: 'Utolsó tevékenység több mint 24 órája',
    no_points: 'Ez a jármű 24 óránál tovább nem küldött pontot',
    clutch: 'Kuplung',
    clutch_switch: 'Kuplung kapcsoló',
    brake_pedal: 'Fék pedál',
    accelerator_pedal: 'Gázpedál',
    pedal_released: 'A pedál elengedve',
    pedal_pressed: 'Pedál lenyomva',
    data_mode: 'Adatmód',
    home_stop: 'A helyére zárva',
    home_moving: 'Útközben a helyszínen',
    roaming_stop: 'Elakadt a roamingban',
    roaming_moving: 'Útközben barangolás',
    unknown_stop: 'Letartóztatás ismeretlen',
    unknown_moving: 'Mozgás ismeretlen',
    engine_coolant_temperature: 'Hűtőfolyadék hőmérséklete',
    engine_temperature: 'Motor hőmérséklet',
    cruise_control_active: 'Tempomat aktív',
    adblue_off: 'Adblue kikapcsolva',
    adblue_red: 'Adblu piros',
    adblue_yellow: 'Adblu sárga',
    adblue_info: 'Adblue info',
    charger_notconnected: 'A töltő nincs csatlakoztatva',
    charger_connected: 'Töltő csatlakoztatva',
    tacho_unknown: 'Ismeretlen',
    tacho_kline: 'K-Line',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'Az elérni kívánt jármű nem elérhető',
    vehicle_with_lock_commands: 'Zárószervekkel ellátott jármű',
    lock: 'Blokk',
    unlock: 'Kinyit',
    send: 'Küld',
    command_sent: 'A parancs sikeresen elküldve',
    turn_on: 'világítani',
    turned_on: 'Bekapcsolva',
    turn_off: 'Kikapcsol',
    turned_off: 'Elhasznált',
    confirm_send_command: 'Biztosan el akarja küldeni a parancsot?',
    temperature: {
        title: 'Hőmérséklet szondák',
        probe: ['1. szonda', '2. szonda', '3. szonda', '4. szonda', '5. szonda', '6. szonda']
    },
    no_power_supply: 'Nincs áram',
    no_gps: 'Nincs GPS',
    tacho_clock_from_updated_error: 'Hibás a tachográf óra szinkronizálása',
    tacho_clock_from_device_error: 'Hibás a tachográf és a vezérlőegység szinkronizálása',
    device_timestamp_error: 'ECU dátum hiba',
    select_color_mode: 'Válasszon színmódot',
    light_mode: 'Fény mód',
    dark_mode: 'Sötét mód',
    color_blind_mode: 'Színvak mód',
    color_blind_dark_mode: 'Színvak sötét mód',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Kiemelt járművek',
            search_trucks_title: 'Járművek keresése',
            search_trucks_tags_title: 'Kapcsolódó címkék',
            search_trucks_status_title: 'Járműtevékenység',
            search_trucks_driver_status_title: 'A sofőr tevékenysége',
            search_near_trucks_title: 'Keresse meg a közeli járműveket'
        },
        detailspanel: {
            bind_vehicle: 'Páros jármű',
            add_new_tag: 'Hozzáadás új címkeként',
            select_new_tag: 'Válasszon vagy keressen egy címkét',
            binded_tags: 'Kapcsolódó címkék',
            binded_tag: 'Kapcsolódó címke'
        },
        analyzer: {
            vehicle_activity_analisys: 'Járműtevékenység elemzés',
            timeline_separator: 'Csoportosít',
            loading_error: 'Hiba történt az adatok betöltése közben'
        },
        search_resources: {
            title: 'Keressen forrásokat',
            description:
                'Keresse meg a járművéhez legközelebb eső benzinkutakat, parkolókat és elérhető területeket.'
        }
    },
    analisys: {
        title: 'Útvonalelemzés',
        select_telemetries: 'Válassza ki az útvonalelemzésben megjelenítendő telemetriákat',
        select_telemetries_description: 'Maximum 4 telemetria kiválasztható a megjelenítéshez',
        chart_groups: {
            movement_data: 'Mozgási adatok',
            engine_data: 'Motoradatok',
            vehicle_data: 'A jármű adatai',
            sensors_data: 'Érzékelő adatok',
            driver_data: 'Vezetői adatok',
            fuel_data: 'Üzemanyag adatok'
        },
        no_data: 'Nincs adat',
        no_data_description:
            'Nincs megjeleníthető útvonaladat ehhez a járműhöz vagy a kiválasztott időszakhoz',
        no_data_contact_support: 'További információért forduljon az ügyfélszolgálathoz'
    },
    planner: {
        user_config: 'Felhasználói konfigurációk',
        no_user_config: 'Nincsenek megtekinthető felhasználói konfigurációk',
        freight_access: 'Hozzáférés a rakományzsákokhoz',
        vehicle_actual_position: 'A jármű jelenlegi helyzete',
        plan_route: 'Tervezze meg az útvonalat',
        select_on_map: 'Válasszon ki egy pontot a térképen',
        select_on_map_option: 'Válassza ki a térképen',
        fill_route_info: 'Töltse ki az útvonal adatait',
        fill_resources: 'Válassza ki a pontot, amelyet útpontként szeretne hozzáadni',
        fill_waypoints: 'Válassza ki a szakaszokat',
        load_breaks: 'Terhelési pihenők',
        load_alternative_routes: 'Alternatív útvonalak betöltése',
        savedroutes: {
            my_routes: 'Az én útvonalaim',
            saved_routes: 'Mentett útvonalak',
            no_saved_routes: 'Még nem mentett el egyetlen útvonalat sem',
            success_saved_route: 'Az útvonal sikeresen elmentve',
            error_saved_route: 'Hiba történt az útvonal mentése közben',
            success_deleted_route: 'Az útvonal sikeresen törölve',
            error_deleted_route: 'Hiba történt az elérési út törlése közben'
        },
        resources: {
            area_search: 'Keresés a sugáron belül',
            station_services: 'Beleértve ezeket a szolgáltatásokat',
            parking_services: 'Parkolási szolgáltatások',
            select_rating: 'Válassza ki a pontszámot',
            select_security: 'Válassza a biztonságot',
            empty_search: 'A keresés nem talált megjeleníthető elemeket',
            delivery_nation: 'Szállítási ország',
            fai_featured: 'Lumesia kapcsolt',
            self_service: 'Önkiszolgáló',
            served: 'Kiszolgált',
            total_parking_spots: 'Összes helyek',
            rating: 'Felhalmozási',
            reviews: 'Vélemények',
            security: 'Biztonság',
            price_per_night: 'Ár éjszakánként'
        },
        emissions_labels: 'Kibocsátások',
        emissions: {
            fuelconsumption: 'Üzemanyag fogyasztás',
            hydrocarbons: 'Szénhidrogének',
            methane: 'Metán',
            hydrocarbonsexceptmethane: 'Szénhidrogének a metán kivételével',
            carbonmonoxide: 'Szén-monoxid',
            carbondioxide: 'Szén-dioxid',
            fossilcarbondioxide: 'Fosszilis szén-dioxid',
            sulphurdioxide: 'A kén-dioxid',
            nitrogenoxides: 'Nitrogén-oxidok',
            nitrogendioxide: 'Nitrogén-dioxid',
            nitrousoxide: 'Dinitrogén-oxid',
            ammonia: 'Ammónia',
            benzene: 'Benzol',
            particles: 'Részecskék',
            numberofparticles: 'A részecskék száma'
        },
        plafond_configuration: {
            title: 'Mennyezeti konfiguráció',
            driving_time_regulation: 'Vezetési idő',
            last_time_the_driver_worked: 'Utolsó sofőr tevékenység',
            eu_driving_time_regulation_for_single_day: 'Egyetlen nap',
            eu_driving_time_regulation_for_single_long_day: 'Egynapos (bónusszal)',
            eu_driving_time_regulation_for_multiple_day: 'Még több nap',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Több nap (legénységgel)',
            accumulated_driving_time_since_lastbreak: 'Több óra vezetés az utolsó szünet óta',
            accumulated_driving_time_since_lastdailyrest: 'Vezetési órák az utolsó napi pihenő óta',
            accumulated_travel_time_since_lastdailyrest: 'Utazási órák az utolsó napi pihenő óta'
        },
        set_route: 'Állítsa be az útvonalat',
        select_profile: 'Profil kiválasztása',
        select_co2_class: 'Válassza ki a kibocsátási osztályt',
        class: 'Osztály',
        billed_roads: 'Fizetős utak',
        waypointevent: 'Állj meg',
        waypoints_label: 'Szakasz',
        add_waypoint: 'Színpad hozzáadása',
        delete_waypoint: 'Szakasz törlése',
        select_waypoint: 'Válassza ki a szakaszt',
        select_resource: 'Válassza ki a pontot, amelyet útpontként szeretne hozzáadni',
        last_waypoint: 'Utolsó megálló',
        waypoints_check: 'Vannak szakaszok, amelyeket be kell fejezni',
        waypoints_required: 'Legalább 2 lépés szükséges',
        countryevent: 'Állapot',
        tollevent: 'Útdíjpénztár',
        event: 'Esemény',
        alpega: 'Alpega',
        alpega_login: 'Bejelentkezés Alpega',
        alpega_searches: 'A kutatásod',
        timocom: 'Timocom',
        pickup: 'Visszavonás',
        delivery: 'Szállítás',
        extimated_cost: 'Becsült költség',
        calculate_route: 'Útvonal kiszámítása',
        calculate_isocrona: 'Számítsa ki az izokrónt',
        complete_step_2: 'Végezze el a 2. lépést',
        reset_route: 'Új útvonal',
        route_error: 'Hiba történt az útvonal kiszámításakor',
        edit_route: 'Útvonal szerkesztése',
        save_route: 'Útvonal mentése',
        delete_route: 'Útvonal törlése',
        route_name: 'Útvonal neve',
        override_route: 'Felülírási útvonal',
        save_as_new_route: 'Új útvonal mentése',
        calculate_route_for: 'Számolja ki az útvonalat',
        ptvexport: 'Exportálás a PTV Navigatorba',
        isocrona: 'Elérhető területek',
        reset_isocrona: 'Állítsa vissza az elérhető területeket',
        search_location: 'Helyek keresése',
        search_station: 'Keresse a forgalmazót',
        search_parking: 'Keressen parkolóhelyet',
        search_poi: 'POI keresése',
        include_plafond: 'Vegye figyelembe a járművezetői korlátot az útvonal kiszámításakor',
        include_plafond_near_vehicles:
            'Keressen olyan járműveket, amelyek vezetőinek elegendő hátralévő elkötelezettsége van ahhoz, hogy megérkezzenek a keresett helyre',
        violated_plafond: 'A kiválasztott útvonal sérti a sofőr korlátozását'
    },
    sharedlinks: {
        back_to_links: 'Menj vissza a linkekhez',
        create_link: 'Hozzon létre hivatkozásokat',
        update_link: 'Link frissítése',
        edit_link: 'Link szerkesztése',
        update_link_expire_date: 'Lejárati dátum frissítése',
        delete_link: 'Link törlése',
        delete_link_confirm: 'Törli a kiválasztott linket?',
        all_your_shared_links: 'Az összes megosztott link',
        no_shared_links: 'Még nem osztott meg járművet',
        vehicles_link: 'Linkek járművekhez',
        select_share_vehicles: 'Válassza ki a megosztani kívánt járműveket',
        select_start_expire_date: 'Válassza ki a kezdési és lejárati dátumot',
        created_link_error: 'Hiba a link létrehozásakor',
        created_link_success: 'Link sikeresen létrehozva',
        updated_link_error: 'Hiba a link frissítésekor',
        updated_link_success: 'A link sikeresen frissítve',
        service_description:
            'Oszd meg ezt a linket a kiválasztott járművek nyomon követésének megjelenítéséhez',
        link_preview: 'Link előnézet',
        share_single_vehicle: 'Ossza meg a jármű helyét',
        track_vehicle_position: 'Kövesse nyomon a kiválasztott járművek helyét',
        start_date_warning: 'A kezdési dátum nem módosítható',
        start_date_condition:
            'Ha módosítani szeretné a kezdési dátumot, létre kell hoznia egy új hivatkozást',
        expire_date_description:
            'Lejárati dátumot jelöl, amely után ez a link már nem lesz elérhető',
        start_expire_date_description:
            'Adjon meg egy kezdeti dátumot, amelytől kezdve ez a hivatkozás elérhető lesz, és egy lejárati dátumot, amely után ez a link már nem lesz elérhető:'
    },
    viewsharedlink: {
        invalid_date_range: 'A kiválasztott dátumtartomány nem érvényes ehhez a linkhez',
        too_early_link: 'Az elérni kívánt link még nem érhető el',
        too_late_link: 'Az elérni kívánt link már nem létezik',
        show_route: 'Útvonal megjelenítése'
    },
    tags: {
        manage_tags: 'Címkék kezelése',
        add_tag: 'Címkék hozzáadása',
        add_tag_placeholder: 'Adja meg a címke nevét',
        add_tag_error: 'Hiba a címke hozzáadásakor',
        add_tag_success: 'A címke sikeresen hozzáadva',
        delete_tag: 'Címkék törlése',
        delete_tag_confirm: 'Törli a kiválasztott címkét?',
        delete_tag_error: 'Hiba a címke törlésekor',
        delete_tag_success: 'A címke sikeresen törölve',
        update_tag: 'Frissítse a címkéket',
        update_tag_error: 'Hiba a címke frissítésekor',
        update_tag_success: 'A címke sikeresen frissítve',
        all_your_tags: 'Az összes címkéd',
        no_tags: 'Még nem hozott létre címkéket',
        tag_name: 'Címke neve'
    },
    manage_poi: {
        title: 'POI-k kezelése',
        your_pois: 'Az Ön POI-jai',
        add_poi: 'POI hozzáadása',
        no_pois: 'Még nem hozott létre POI-kat',
        fields: {
            name: 'Keresztnév',
            type: 'Fickó',
            color: 'Szín',
            note: 'jegyzet',
            address: 'Cím keresése',
            latitude: 'Szélességi kör',
            longitude: 'Hosszúság',
            radius: 'Keresési sugár',
            radius_description: 'A geokerítés engedélyezéséhez állítson be nullánál nagyobb értéket'
        },
        types_poi: {
            all: 'Mindenki',
            headquarter: 'Webhely',
            truck_washes: 'Teherautó mosók',
            blacklist: 'Feketelista',
            end_of_the_line: 'Végállomás',
            customers: 'Ügyfelek',
            stops: 'Állj meg',
            suppliers: 'Szolgáltatók',
            interports: 'Interportok',
            markets: 'Piacok',
            large_square: 'Négyzetek',
            poi_distributors: 'AKKOR a forgalmazók',
            poi_workshops: 'MAJD Workshopok',
            harbors: 'Portok',
            restaurants: 'Éttermek',
            stations: 'Állomások'
        },
        messages: {
            add_poi_error: 'Hiba POI hozzáadásakor',
            add_poi_success: 'MAJD sikeresen hozzáadva',
            update_poi_error: 'Hiba történt a POI frissítésekor',
            update_poi_success: 'MAJD sikeresen frissítve',
            delete_poi_error: 'Hiba történt a POI törlésekor',
            delete_poi_success: 'MAJD sikeresen törölve'
        }
    },
    vehicles_cross: {
        title: 'Jármű átjárás',
        fields: {
            address: 'Cím',
            radius: 'sugár',
            time_interval: 'Idő telik el',
            time_interval_description: 'Válassza ki a 24 órás maximális időtartamot'
        },
        messages: {
            max_time_interval: 'A maximális időtartam 24 óra',
            error_searching: 'Hiba történt a járművek keresése közben',
            searching: 'Kutatás folyamatban...',
            long_loading: 'A keresés eltarthat néhány percig, kérjük, várjon...',
            no_vehicles_cross: 'Nem találhatók járművek'
        }
    },
    plafonds: {
        session: 'Munkamenet szünet',
        break: 'Szünet',
        drive_occurring: 'Egy Vezetési Időszak van folyamatban:',
        drive_max_label: 'Maximális vezetési idő',
        drive_max_value: '4h 30m',
        drive_done: 'Elvégzett vezetés',
        session_title: 'Információk a Szétszórt Szünetről',
        session_tooltip: 'Lehetőség van Szétszórt Szünetet tartani',
        session_done: 'Legalább 15 perces szünetet észleltek.',
        session_info: 'A Szétszórt Szünet első része már megtörtént',
        session_descr:
            "Emlékeztetjük, hogy a hatályos szabályok szerint lehetőség van 15'+30' vagy 15'+15'+15' szünetet tartani (az elvégzett szállítás típusától függően).",
        session_choice: 'Lehetőség van legalább <b>30m</b> szünetet tartani a következő',
        brake_start: 'Szünet kezdete',
        brake_done: 'Szünet megtörtént',
        daily: 'Napi',
        weekly: 'Heti',
        driver_in_drive: 'Te vezetsz mellette',
        driver_in_rest: 'Maradék pihenő',
        driver_in_work: 'Munka',
        driver_in_available: 'Elérhetőség',
        driver_in_availability: 'Elérhetőség',
        current_activity: 'Jelenlegi tevékenység',
        mini_plafond: 'Mini-mennyezet',
        last_update: 'Utolsó frissítés',
        panel_title: 'Mennyezeti panel',
        residual_commitment: 'Maradék elkötelezettség',
        daily_driving: 'Napi útmutató',
        weekly_guide: 'Heti útmutató',
        biweekly_guide: 'Kétheti útmutató',
        last_rest: 'Utolsó heti pihenőidő',
        minimal_duration_next: 'Minimális időtartam a következő',
        top_start_until: 'Kezdje',
        reduced_weekly_rest: 'Csökkentett heti pihenőidő ennek pótlására',
        time_to_compensate: 'Ideje kompenzálni',
        time_to_compensate_until: 'által kompenzálni',
        footer_message: '* az idő kiszámítása a fennmaradó bónuszok figyelembevételével történik',
        driver_in_block: 'A járművezetői korlát kimerült',
        résidu_commitment: 'Maradék elkötelezettség',
        week_guide: 'Hebdomadaire útmutató',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: '403-as hiba',
            description:
                'Tisztelt Ügyfelünk! Tájékoztatjuk, hogy fiókja jelenleg nincs konfigurálva a szolgáltatás eléréséhez. Magáról a szolgáltatásról szóló információk fogadása',
            retry_login: 'Kérjük, próbáljon meg újra bejelentkezni',
            contact_us: 'Lépjen kapcsolatba velünk'
        }
    },
    ptv: {
        maxpermittedweight: 'Nehézsúlyú',
        minpermittedweight: 'Minimális súly',
        zonename: 'Terület',
        unauthorizedtype: 'Jogosulatlan kód',
        maxemissionstandardeu: 'Emissziós osztály',
        fueltype: 'Üzemanyagtípus',
        loadtype: 'Szállítás típusa',
        opening: 'Lovagol',
        maxheight: 'maximum magasság',
        maxweight: 'Nehézsúlyú',
        maxwidth: 'Maximális szélesség',
        maxlength: 'Maximális hossz',
        absolutespeed: 'Sebesség',
        hazardoustowaters: 'Vízveszélyes árut szállító járművek betiltása',
        hazardousgoods: 'Veszélyes árut szállító járművekre vonatkozó tilalom',
        combustibles: 'Üzemanyagot szállító járművek betiltása',
        maxaxleload: 'Maximális súly a tengelyeken',
        totalpermittedweight: 'Nehézsúlyú',
        tunnelrestriction: 'Alagútkorlátozási kód',
        kingpintorearaxle: 'A királycsap és a tengely közötti távolság',
        numberofaxles: 'Tengelyek száma',
        length: 'Farok',
        delay: 'Késleltetés',
        message: {
            text: 'Üzenet'
        }
    },
    options_interface: 'Opciók felület'
}
