export default {
    open: 'Tu deschizi',
    close: 'Închide',
    apply: 'aplica',
    cancel: 'Anulare',
    search: 'Aproape',
    cancel_search: 'Anulează căutarea',
    edit_search: 'Editați căutarea',
    erase: 'Anulare',
    back_to_results: 'Reveniți la rezultate',
    save: 'Salvați',
    retry: 'Încearcă din nou',
    click_here: 'Click aici',
    copy: 'Copie',
    copied: 'Copiat',
    copied_coordinates: 'Coordonatele copiate',
    select: 'Selectați',
    selected: 'Selectat',
    selected_plural: 'Selectat',
    deselect: 'Deselectați',
    select_multi_vehicles: 'Selectați unul sau mai multe vehicule',
    include: 'Include',
    exclude: 'Exclude',
    balanced: 'Echilibrat',
    input: 'Intrare',
    output: 'Ieșire',
    verified: 'Verificat',
    empty_search: 'Nici un rezultat gasit',
    read_more: 'Citeşte mai mult',
    details: 'Detalii',
    actual: 'Actual',
    residual_time: 'Timp rămas',
    next: 'Următorul',
    previous: 'Anterior',
    menu_open: 'Deschide meniul',
    hide: 'Ascunde',
    show: 'Spectacol',
    none: 'Nimeni',
    none_alt: 'Nici unul',
    all: 'Toata lumea',
    all_alt: 'Toate',
    default: 'Mod implicit',
    settings: 'Setări',
    reset_settings: 'Reseteaza setarile',
    reset_settings_success: 'Setările sunt resetate cu succes',
    multiselect_default_value: {
        singular: 'Valoarea selectată:',
        plural: 'Valori selectate:'
    },
    available: 'Disponibil',
    unavailable: 'Nu este disponibil',
    not_available: 'Nu este disponibil',
    not_available_plural: 'Nu este disponibil',
    from: 'de la',
    to: 'la',
    of: 'De',
    yes: 'da',
    no: 'Nu',
    minutes: 'Minute',
    hours: 'Ore',
    hour: 'Acum',
    days: 'Zile',
    day: 'Zi',
    months: 'Luni',
    month: 'Lună',
    years: 'Ani',
    year: 'An',
    days_names: ['duminică', 'luni', 'marţi', 'miercuri', 'joi', 'vineri', 'sâmbătă'],
    days_names_short: ['Soare', 'Lun', 'Mar', 'mier', 'joi', 'vineri', 'sat'],
    months_names: [
        'ianuarie',
        'februarie',
        'Martie',
        'Aprilie',
        'Mai',
        'iunie',
        'iulie',
        'August',
        'Septembrie',
        'octombrie',
        'noiembrie',
        'decembrie'
    ],
    months_names_short: [
        'Gen',
        'feb',
        'Mar',
        'Aprilie',
        'Mag',
        'De mai jos',
        'iul',
        'aug',
        'A stabilit',
        'oct',
        'nov',
        'Dec'
    ],
    areas: 'Zone',
    area: 'Zonă',
    time: 'Timp (min)',
    time_label: 'Timp',
    cost: 'Cost',
    toll_cost: 'Costul taxei',
    km_cost: 'Cost pe km',
    hourly_cost: 'Costul orar',
    working_cost: 'Costul total al omului',
    total_cost: 'Cost total',
    expire_date: 'Data expirării',
    start_date: 'Data de început',
    recap: 'rezumat',
    start: 'start',
    starting_point: 'Plecare',
    end: 'Sfârşit',
    finish_point: 'ajung',
    duration: 'Durată',
    distance: 'Distanţă',
    daily_distance: 'Km parcursi zilnic',
    distance_total: 'Distanta totala',
    gps_distance: 'Distanță (GPS)',
    distance_gps: 'Km parcursi zilnic (GPS)',
    pause: 'Pauză',
    segment: 'Se ocupă cu',
    start_time: 'Timpul de începere',
    end_time: 'Sfârșitul timpului',
    service_time: 'Timp de service',
    waypoint_load: 'Încărcare la bord',
    state: 'Stat',
    violated_plafond: 'Încălcarea plafonului',
    traffic_delay: 'Întârziere în trafic',
    company: 'Agenţie',
    address: 'Abordare',
    position: 'Poziţie',
    route: 'cale',
    filter_date: 'Filtrați după dată',
    warning: 'Atenţie',
    no_warning: 'Fără avertismente',
    alerts: 'Notificări',
    trucks_with_alerts: 'Vehicule cu avertismente',
    bluetooth_devices: 'Dispozitive Bluetooth',
    no_bluetooth_devices: 'Nu s-au găsit dispozitive Bluetooth',
    connected: 'Conectat',
    disconnected: 'Deconectat',
    bluetooth_types: {
        temperature_probe: 'Sonda de temperatura',
        door_probe: 'Ușă și sondă de temperatură'
    },
    back_to_home: 'Înapoi acasă',
    cerca: 'Aproape',
    search_vehicle: 'Cauta vehiculul',
    filter: 'Filtru',
    filter_label: 'Filtru',
    filters_label: 'Filtre',
    filter_by: 'Filtreaza dupa',
    operations: 'Operațiuni',
    filter_vehicles: 'Filtrați vehiculele',
    filter_applied: 'Filtrul aplicat cu succes',
    filter_canceled: 'Filtrul a fost șters cu succes',
    order_by: 'Filtrează după',
    order: 'Ordin',
    ascending: 'Creştere',
    descending: 'Descendentă',
    value: 'Valoare',
    select_filter: 'Selectați filtrul',
    sort_vehicles: 'Comanda vehicule',
    sort_for_alphanumeric: 'Alfabetic',
    sort_for_lastpoint: 'Cronologic',
    sort_for_company: 'Agenţie',
    sort_for_activity: 'Activitate',
    company_select: 'Selectați compania',
    no_companies_founded: 'Nu au fost găsite companii',
    multicompany: 'Filtrați după companie',
    no_vehicles_founded: 'Nu au fost găsite vehicule',
    print: 'presa',
    export: 'Export',
    print_recap: 'Imprimați rezumatul',
    select_date: 'Selectați o dată',
    add: 'adăuga',
    add_new: 'Adăuga nou',
    delete: 'Șterge',
    edit: 'Editați | ×',
    options: 'Opțiuni',
    login: 'Log in',
    logout: 'Ieși afară',
    logout_confirm: 'Esti sigur ca vrei sa renunti?',
    login_button: 'Log in',
    connect: 'Conectați',
    logged: 'Conectat',
    manage: 'Administra',
    loading: 'Se încarcă',
    loading_timeout: 'Eroare de rețea, reîncărcați pagina sau încercați din nou mai târziu',
    go_back: 'Întoarce-te',
    select_coordinates: 'Selectați coordonatele',
    other: 'Alte',
    options_general: 'General',
    options_language: 'Limbă',
    change_interface_language: 'Selectați limba interfeței',
    change_infringment_language: 'Selectați țara de încălcare',
    change_timezone: 'Selectați fusul orar',
    save_language_settings: 'Salvați setările de limbă',
    vehicle_list_options: 'Lista de vehicule',
    select_view: 'Selectați vizualizarea',
    select_fields_placeholder: 'Selectați câmpurile de vizualizat',
    selected_field: 'Câmp selectat',
    selected_fields: 'Câmpurile selectate',
    list: 'Listă',
    table: 'Masa',
    view_options: 'Vizualizați opțiunile',
    fluid_table: 'Lățimea dinamică',
    fluid_table_descr: 'Faceți fluidă lățimea tabelului pe baza câmpurilor selectate',
    vehicle_fields_list: 'Ce informații doriți să apară pentru fiecare vehicul? (Max 3)',
    vehicle_fields_list_warning:
        'Atenție: câmpul nu va fi afișat dacă nu este disponibil pentru vehicul',
    vehicle_identification: 'Cum doriți ca vehiculul să fie identificat în lista principală?',
    apply_to_marker: 'Aplicați această setare și vehiculelor de pe hartă',
    element_icon_section: 'Pictograma elementului',
    element_title_section: 'Titlul articolului',
    element_fields_list_section: 'Lista câmpurilor de elemente',
    element_icon_description: 'Selectați pictograma de afișat pentru element',
    element_icon: 'Pictogramă',
    element_icon_color: 'Culoarea pictogramei',
    options_map: 'Opțiuni pentru hartă',
    vehicles_clustering: 'Gruparea vehiculelor',
    vehicles_clustering_warning: 'Actualizarea acestei setări necesită reîncărcarea paginii',
    options_marker: 'Opțiuni de marcare',
    size: 'Dimensiune',
    font_size: 'Mărimea textului',
    font_color: 'Culoarea textului',
    background_color: 'Culoare de fundal',
    opacity: 'Transparenţă',
    color: 'Culoare',
    small: 'Mic',
    medium: 'Mediu',
    large: 'Grozav',
    additional_info: 'Informații suplimentare',
    analysis_options: 'Analiza traseului',
    options_chart: 'Opțiuni de diagramă',
    options_timeline: 'Opțiuni de cronologie',
    hide_timeline: 'Ascunde cronologia',
    map: 'Hartă',
    silica: 'Silice',
    google: 'Google',
    google_satellite: 'Google Satellite',
    sandbox: 'Cutii cu nisip',
    silkysand: 'Nisip mătăsos',
    gravelpit: 'Pietriș',
    open_street_maps: 'Deschide Street Maps',
    arcgis_satellite: 'ArcGIS Satellite',
    traffic: 'Trafic',
    heavy_vehicles_ban: 'Interdicții pentru vehiculele grele',
    driver: 'Conducător auto',
    no_driver: 'Fără șofer',
    drivers: 'Șoferii',
    driver_card: 'Card de șofer',
    name: 'Nume',
    surname: 'Nume de familie',
    driver_activity: 'Activitatea șoferului',
    driver_status: 'Starea șoferului',
    activity_type: 'Gen de activitate',
    activity: 'Activitate',
    no_activity: 'Fără activitate',
    driver_at_rest: 'Șofer în repaus',
    driver_available: 'Driver disponibil',
    driver_at_work: 'Șofer la serviciu',
    driver_driving: 'Conducerea șoferului',
    inactive_vehicle: 'Vehicul inactiv',
    last_driver_detected: 'Ultimul șofer detectat',
    rest: 'Odihnă',
    work: 'Muncă',
    driving: 'Ghid',
    availability: 'Disponibilitate',
    codriving: 'Conduceți cu un echipaj',
    codriver: 'Echipajul',
    break: 'Întrerupeți sesiunea',
    daily_rest: 'Odihnă zilnică',
    violation: 'Încălcare',
    to_begin_within: 'Pentru a fi început de',
    to_end_within: 'Pentru a fi terminat de',
    after_total_time_travel: 'După o călătorie totală de',
    after_total_distance_travel: 'Dupa o distanta totala parcursa de',
    residual_commitment_type: 'Tipul angajamentului rezidual',
    residual_commitment: 'Angajament rezidual',
    commitment_hours: 'Ore de angajament',
    commitment_hours_description:
        'Găsiți toate vehiculele ai căror șoferi au un angajament rezidual mai mare decât cel indicat',
    driver_plafond: 'Plafonul șoferului',
    residual_driving: 'Îndrumare reziduală',
    residual_bonuses: 'Bonusuri reziduale',
    consider_bonuses: 'Timpul se calculează luând în considerare bonusurile reziduale',
    current_activity: 'Activitate curenta',
    next_activity: 'Următoarea activitate',
    view_plafond_detail: 'Vedeți detaliile tavanului',
    card: 'Hârtie',
    driver_change: 'Schimbarea șoferului',
    shared_links: 'Linkuri partajate',
    gf: 'ATUNCI Detectat',
    analyze: 'A analiza',
    plan: 'Plan',
    vehicle: 'Vehicul',
    vehicles: 'Vehicule',
    vehicle_info: 'Informații despre vehicul',
    vehicle_type: 'Tip de vehicul',
    vehicle_body: 'Caroseria vehiculului',
    truck_status: 'Starea vehiculului',
    brand: 'Marca',
    model: 'Model',
    no_card: 'Hârtia nu a fost introdusă',
    no_card_driving: 'Conducere fără hârtie',
    speed: 'Viteză',
    tacho_speed: 'Viteza (tahograf)',
    vehicle_hooked: 'Vehicul atasat',
    truck_hooked_text: 'Acest vehicul este atasat la următorul remorcher: ',
    trailer_hooked_text: 'Acest remorcher este atasat la următorul vehicul: ',
    no_driver_rfid_info: 'Carteira de șofer nu este înregistrată în lista de șoferi',
    gps_speed: 'Viteza (GPS)',
    level: 'Nivel',
    battery: 'Tobe',
    low_battery_level: 'Nivel scăzut al bateriei',
    fuel: 'Combustibil',
    fuel_level: 'Nivelul combustibilului',
    low_fuel_level: 'Putin combustibil',
    consumption: 'Consum',
    consumptions: 'Consum',
    fuel_consumption: 'Consum de combustibil',
    fuel_avg_consumption: 'Consumul mediu zilnic de combustibil',
    altitude: 'Altitudine',
    weight: 'Greutate',
    lastpoint_weight: 'Greutate (ultimul punct)',
    vehicle_weight: 'Greutate (t)',
    vehicle_length: 'Lungime (m)',
    vehicle_width: 'lățime (m)',
    vehicle_height: 'Înălțime (m)',
    odometer: 'Odometru',
    odometer_tacho: 'Odometru (tahograf)',
    odometer_gps: 'Odometru (GPS)',
    gps_odometer: 'Odometru (GPS)',
    last_point: 'Ultimul punct',
    moving: 'In miscare',
    stopped: 'Oprit',
    engine_on: 'Motorul merge',
    ignition: 'Aprinderea pusă',
    engine_off: 'Motorul oprit',
    engine_on_off: 'Pornirea motorului',
    ignition_on: 'Aprinderea pusă',
    ignition_off: 'Contactul oprit',
    device: 'Dispozitiv',
    plate: 'Farfurie',
    chassis: 'Şasiu',
    status: 'stare',
    custom_id: 'Nume personalizat',
    heading: 'Direcţie',
    rpm_engine: 'Turația motorului',
    pto_active: 'Priza de putere activă',
    pto_state: 'Stare PTO',
    device_timestamp: 'Timestamp-ul',
    info: 'Info',
    solar_day_report: 'Raportul zilei calendaristice',
    telemetry: 'Telemetrie',
    parameters: 'Parametrii',
    engine_coolant_temp: 'Temperatura motorului',
    engine_rpm: 'Turația motorului',
    dallas_temp_1: 'Temperatura 1',
    dallas_temp_2: 'Temperatura 2',
    dallas_temp_id_1: 'ID temperatură 1',
    dallas_temp_id_2: 'ID temperatură 2',
    digital_input_1: 'Intrare digitală 1',
    digital_input_2: 'Intrare digitala 2',
    digital_input_3: 'Intrare digitală 3',
    digital_input_4: 'Intrare digitală 4',
    digital_input_5: 'Intrare digitală 5',
    battery_level: 'Nivelul bateriei',
    adblue_liters: 'Litri Adblue',
    adblue_level: 'Nivel Adblue',
    gnss_status: 'starea GNSS',
    gnss_status_values: [
        'Uzat',
        'Pornit, fără GPS',
        'Pornit, fără sateliți',
        'Pornit, cu sateliți',
        'Pe, în somn',
        'Statut protejat'
    ],
    gnss_off: 'GNSS dezactivat',
    gnss_on_fix: 'GNSS activat, cu sateliți',
    gnss_on_without_fix: 'GNSS activat, fără sateliți',
    gnss_on_sleep: 'GNSS activat, în repaus',
    gnss_on_protection: 'GNSS activat, starea protecției',
    gsm_signal: 'semnal GSM',
    tacho_source: 'Sursa tahografului',
    external_voltage: 'Cura de slabire',
    tacho_overspeed: 'Tahograf cu viteză excesivă',
    no_points_title: 'Ultima activitate acum mai bine de 24 de ore',
    no_points: 'Acest vehicul nu a trimis niciun punct de mai mult de 24 de ore',
    clutch: 'Ambreiaj',
    clutch_switch: 'Comutator ambreiaj',
    brake_pedal: 'Pedala de frana',
    accelerator_pedal: 'Pedala de acceleratie',
    pedal_released: 'Pedala eliberată',
    pedal_pressed: 'Pedala apăsată',
    data_mode: 'Modul de date',
    home_stop: 'Blocat pe loc',
    home_moving: 'În mișcare la fața locului',
    roaming_stop: 'Blocat în roaming',
    roaming_moving: 'Roaming în mișcare',
    unknown_stop: 'Arestare necunoscută',
    unknown_moving: 'Mișcare necunoscută',
    engine_coolant_temperature: 'Temperatura agentului de răcire',
    engine_temperature: 'Temperatura motorului',
    cruise_control_active: 'Cruise control activ',
    adblue_off: 'Adblue dezactivat',
    adblue_red: 'Adblue roșu',
    adblue_yellow: 'Adblue galben',
    adblue_info: 'Informații Adblue',
    charger_notconnected: 'Încărcătorul nu este conectat',
    charger_connected: 'Încărcătorul conectat',
    tacho_unknown: 'Necunoscut',
    tacho_kline: 'Linia K',
    tacho_allcan: 'AUTOBUZ-CAN',
    tacho_can: 'AUTOBUZ-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'Vehiculul pe care încercați să îl accesați nu este disponibil',
    vehicle_with_lock_commands: 'Vehicul cu comenzi de blocare',
    lock: 'bloc',
    unlock: 'Deblocați',
    send: 'Trimite',
    command_sent: 'Comanda trimisă cu succes',
    turn_on: 'lumina pe',
    turned_on: 'Pornit',
    turn_off: 'Opriți',
    turned_off: 'Uzat',
    confirm_send_command: 'Ești sigur că vrei să trimiți comanda?',
    temperature: {
        title: 'Sonde de temperatură',
        probe: ['Sonda 1', 'Sonda 2', 'Sonda 3', 'Sonda 4', 'Sonda 5', 'Sonda 6']
    },
    no_power_supply: 'Fara putere',
    no_gps: 'Fara GPS',
    tacho_clock_from_updated_error: 'Eroare de sincronizare a ceasului tahografului',
    tacho_clock_from_device_error: 'Eroare de sincronizare între tahograf și unitatea de control',
    device_timestamp_error: 'Eroare de data ECU',
    select_color_mode: 'Selectați modul de culoare',
    light_mode: 'Modul de lumină',
    dark_mode: 'Modul întunecat',
    color_blind_mode: 'Modul daltonism',
    color_blind_dark_mode: 'Modul întunecat orb',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Vehicule prezentate',
            search_trucks_title: 'Căutați vehicule',
            search_trucks_tags_title: 'Etichete asociate',
            search_trucks_status_title: 'Activitatea vehiculului',
            search_trucks_driver_status_title: 'Activitatea șoferului',
            search_near_trucks_title: 'Găsiți vehicule în apropiere'
        },
        detailspanel: {
            bind_vehicle: 'Pereche vehicul',
            add_new_tag: 'Adăugați ca etichetă nouă',
            select_new_tag: 'Selectați sau căutați o etichetă',
            binded_tags: 'Etichete asociate',
            binded_tag: 'Etichetă asociată'
        },
        analyzer: {
            vehicle_activity_analisys: 'Analiza activității vehiculului',
            timeline_separator: 'A se grupa cu',
            loading_error: 'A apărut o eroare la încărcarea datelor'
        },
        search_resources: {
            title: 'Căutați resurse',
            description:
                'Găsiți benzinăriile, parcările și zonele accesibile cele mai apropiate de vehiculul dvs.'
        }
    },
    analisys: {
        title: 'Analiza traseului',
        select_telemetries: 'Selectați telemetriile de afișat în analiza rutei',
        select_telemetries_description: 'Este posibil să selectați maximum 4 telemetrii de afișat',
        chart_groups: {
            movement_data: 'Date de mișcare',
            engine_data: 'Datele motorului',
            vehicle_data: 'Datele vehiculului',
            sensors_data: 'Datele senzorului',
            driver_data: 'Datele șoferului',
            fuel_data: 'Date despre combustibil'
        },
        no_data: 'Nu există date disponibile',
        no_data_description:
            'Nu există date de rută de afișat pentru acest vehicul sau pentru perioada selectată',
        no_data_contact_support: 'Contactați asistența pentru clienți pentru mai multe informații'
    },
    planner: {
        user_config: 'Configurații utilizator',
        no_user_config: 'Nu există configurații de utilizator de vizualizat',
        freight_access: 'Acces la sacii de marfă',
        vehicle_actual_position: 'Poziția actuală a vehiculului',
        plan_route: 'Planificați traseul',
        select_on_map: 'Selectați un punct de pe hartă',
        select_on_map_option: 'Selectați pe hartă',
        fill_route_info: 'Completați informațiile despre traseu',
        fill_resources: 'Selectați punctul de adăugat ca punct de trecere',
        fill_waypoints: 'Selectați etapele',
        load_breaks: 'Odihnă de încărcare',
        load_alternative_routes: 'Încărcați rute alternative',
        savedroutes: {
            my_routes: 'Rutele mele',
            saved_routes: 'Rute salvate',
            no_saved_routes: 'Nu ați salvat încă nicio rută',
            success_saved_route: 'Traseul dvs. a fost salvat cu succes',
            error_saved_route: 'A apărut o eroare la salvarea traseului',
            success_deleted_route: 'Traseul dvs. a fost șters cu succes',
            error_deleted_route: 'A apărut o eroare la ștergerea căii'
        },
        resources: {
            area_search: 'Căutați în rază',
            station_services: 'Inclusiv aceste servicii',
            parking_services: 'Servicii de parcare',
            select_rating: 'Selectați scorul',
            select_security: 'Selectați securitate',
            empty_search: 'Căutarea nu a găsit niciun element de afișat',
            delivery_nation: 'Țara de livrare',
            fai_featured: 'Lumesia afiliată',
            self_service: 'Autoservire',
            served: 'Servit',
            total_parking_spots: 'Total locuri',
            rating: 'Acumulare',
            reviews: 'Recenzii',
            security: 'Siguranță',
            price_per_night: 'Pret pe noapte'
        },
        emissions_labels: 'Emisii',
        emissions: {
            fuelconsumption: 'Consum de combustibil',
            hydrocarbons: 'Hidrocarburi',
            methane: 'Metan',
            hydrocarbonsexceptmethane: 'Hidrocarburi, cu excepția metanului',
            carbonmonoxide: 'Monoxid de carbon',
            carbondioxide: 'Dioxid de carbon',
            fossilcarbondioxide: 'Dioxid de carbon fosil',
            sulphurdioxide: 'Dioxid de sulf',
            nitrogenoxides: 'Oxizi de azot',
            nitrogendioxide: 'Dioxid de azot',
            nitrousoxide: 'Oxid de azot',
            ammonia: 'Amoniac',
            benzene: 'Benzen',
            particles: 'Particule',
            numberofparticles: 'Numărul de particule'
        },
        plafond_configuration: {
            title: 'Configurația tavanului',
            driving_time_regulation: 'Timp de condus',
            last_time_the_driver_worked: 'Ultima activitate de șofer',
            eu_driving_time_regulation_for_single_day: 'O singura zi',
            eu_driving_time_regulation_for_single_long_day: 'O singură zi (cu bonus)',
            eu_driving_time_regulation_for_multiple_day: 'Mai multe zile',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Mai multe zile (cu echipaj)',
            accumulated_driving_time_since_lastbreak: 'Ore de condus de la ultima pauză',
            accumulated_driving_time_since_lastdailyrest:
                'Ore de condus de la ultima odihnă zilnică',
            accumulated_travel_time_since_lastdailyrest:
                'Ore de călătorie de la ultima odihnă zilnică'
        },
        set_route: 'Setați ruta',
        select_profile: 'Selectați profilul',
        select_co2_class: 'Selectați clasa de emisie',
        class: 'Clasă',
        billed_roads: 'Drumurile cu taxă',
        waypointevent: 'Stop',
        waypoints_label: 'Etape',
        add_waypoint: 'Adaugă etapa',
        delete_waypoint: 'Șterge etapa',
        select_waypoint: 'Selectați etapa',
        select_resource: 'Selectați punctul de adăugat ca punct de trecere',
        last_waypoint: 'Ultima oprire',
        waypoints_check: 'Sunt etape de parcurs',
        waypoints_required: 'Sunt necesari cel putin 2 pasi',
        countryevent: 'Stat',
        tollevent: 'Cabină de taxare',
        event: 'Eveniment',
        alpega: 'Alpega',
        alpega_login: 'Autentificare Alpega',
        alpega_searches: 'Cercetarea dvs',
        timocom: 'Timocom',
        pickup: 'Retrage',
        delivery: 'Livrare',
        extimated_cost: 'Cost estimat',
        calculate_route: 'Calculați traseul',
        calculate_isocrona: 'Calculați izocrona',
        complete_step_2: 'Finalizați pasul 2',
        reset_route: 'Rută nouă',
        route_error: 'A apărut o eroare la calcularea rutei',
        edit_route: 'Editați traseul',
        save_route: 'Salvați traseul',
        delete_route: 'Ștergeți traseul',
        route_name: 'Numele căii',
        override_route: 'Suprascrie calea',
        save_as_new_route: 'Salvați traseul nou',
        calculate_route_for: 'Calculați ruta pentru',
        ptvexport: 'Exportați în PTV Navigator',
        isocrona: 'Zone accesibile',
        reset_isocrona: 'Resetați zonele accesibile',
        search_location: 'Căutați locații',
        search_station: 'Caută distribuitor',
        search_parking: 'Caută parcare',
        search_poi: 'Căutați POI',
        include_plafond: 'Includeți limita șoferului în calculul rutei',
        include_plafond_near_vehicles:
            'Găsiți vehicule cu șoferi cu suficient angajament rămas pentru a ajunge la locația căutată',
        violated_plafond: 'Ruta selectată încalcă limita șoferului'
    },
    sharedlinks: {
        back_to_links: 'Reveniți la linkuri',
        create_link: 'Creați linkuri',
        update_link: 'Actualizați linkul',
        edit_link: 'Editați linkul',
        update_link_expire_date: 'Actualizați data de expirare',
        delete_link: 'Ștergeți linkul',
        delete_link_confirm: 'Ștergeți linkul selectat?',
        all_your_shared_links: 'Toate linkurile dvs. partajate',
        no_shared_links: 'Nu ați distribuit încă niciun vehicul',
        vehicles_link: 'Link-uri pentru vehicule',
        select_share_vehicles: 'Selectați vehiculele de partajat',
        select_start_expire_date: 'Selectați data de începere și de expirare',
        created_link_error: 'Eroare la crearea linkului',
        created_link_success: 'Link creat cu succes',
        updated_link_error: 'Eroare la actualizarea linkului',
        updated_link_success: 'Link-ul a fost actualizat cu succes',
        service_description:
            'Distribuiți acest link pentru a afișa urmărirea vehiculelor selectate',
        link_preview: 'Previzualizare link',
        share_single_vehicle: 'Distribuiți locația acestui vehicul',
        track_vehicle_position: 'Urmăriți locația vehiculelor selectate',
        start_date_warning: 'Nu este posibilă modificarea datei de începere',
        start_date_condition:
            'Dacă doriți să schimbați data de începere, trebuie să creați un nou link',
        expire_date_description:
            'Indică o dată de expirare, după care acest link nu va mai fi disponibil',
        start_expire_date_description:
            'Indicați o dată inițială, de la care acest link va fi disponibil, și o dată de expirare, după care acest link nu va mai fi disponibil:'
    },
    viewsharedlink: {
        invalid_date_range:
            'Intervalul de date pe care l-ați selectat nu este valid pentru acest link',
        too_early_link: 'Linkul pe care încercați să îl accesați nu este încă disponibil',
        too_late_link: 'Linkul pe care încercați să îl accesați nu mai există',
        show_route: 'Arată traseul'
    },
    tags: {
        manage_tags: 'Gestionați etichetele',
        add_tag: 'Adaugă etichete',
        add_tag_placeholder: 'Introduceți numele etichetei',
        add_tag_error: 'Eroare la adăugarea etichetei',
        add_tag_success: 'Etichetă adăugată cu succes',
        delete_tag: 'Ștergeți etichetele',
        delete_tag_confirm: 'Ștergeți eticheta selectată?',
        delete_tag_error: 'Eroare la ștergerea etichetei',
        delete_tag_success: 'Eticheta a fost ștearsă cu succes',
        update_tag: 'Actualizați etichetele',
        update_tag_error: 'Eroare la actualizarea etichetei',
        update_tag_success: 'Eticheta a fost actualizată cu succes',
        all_your_tags: 'Toate etichetele tale',
        no_tags: 'Nu ați creat încă nicio etichetă',
        tag_name: 'Nume eticheta'
    },
    manage_poi: {
        title: 'Gestionați punctele de interes',
        your_pois: 'POI-urile dvs',
        add_poi: 'Adăugați POI',
        no_pois: 'Nu ați creat încă niciun POI',
        fields: {
            name: 'Nume',
            type: 'Tip',
            color: 'Culoare',
            note: 'Notă',
            address: 'Caută adresa',
            latitude: 'Latitudine',
            longitude: 'Longitudine',
            radius: 'Raza de căutare',
            radius_description: 'Pentru a activa geofencing, setați o valoare mai mare decât zero'
        },
        types_poi: {
            all: 'Toata lumea',
            headquarter: 'Site',
            truck_washes: 'Spălătorii auto de camioane',
            blacklist: 'Lista neagră',
            end_of_the_line: 'Terminus',
            customers: 'Clienții',
            stops: 'Stop',
            suppliers: 'Furnizorii',
            interports: 'Interporturi',
            markets: 'Piețele',
            large_square: 'Pătrate',
            poi_distributors: 'APOI Distribuitori',
            poi_workshops: 'APOI Ateliere',
            harbors: 'Porturi',
            restaurants: 'Restaurante',
            stations: 'Statii'
        },
        messages: {
            add_poi_error: 'Eroare la adăugarea POI',
            add_poi_success: 'ATUNCI adăugat cu succes',
            update_poi_error: 'Eroare la actualizarea POI',
            update_poi_success: 'ATUNCI actualizat cu succes',
            delete_poi_error: 'Eroare la ștergerea POI',
            delete_poi_success: 'ATUNCI șters cu succes'
        }
    },
    vehicles_cross: {
        title: 'Trecerea vehiculului',
        fields: {
            address: 'Abordare',
            radius: 'rază',
            time_interval: 'Interval de timp',
            time_interval_description: 'Selectați un interval de timp maxim de 24 de ore'
        },
        messages: {
            max_time_interval: 'Intervalul de timp maxim este de 24 de ore',
            error_searching: 'A apărut o eroare la căutarea vehiculelor',
            searching: 'Cercetare in curs...',
            long_loading: 'Căutarea poate dura câteva minute, așteptați...',
            no_vehicles_cross: 'Nu au fost găsite vehicule'
        }
    },
    plafonds: {
        session: 'Pauză de sesiune',
        break: 'Pauză',
        drive_occurring: 'Este în curs o Perioadă de Conducere:',
        drive_max_label: 'Timp maxim de conducere',
        drive_max_value: '4h 30m',
        drive_done: 'Conducere efectuată',
        session_title: 'Informații despre Pauza Fracționată',
        session_tooltip: 'Este posibil să faceți o Pauză Fracționată',
        session_done: 'A fost detectată o pauză de cel puțin 15 minute.',
        session_info: 'Prima parte a unei Pauze Fracționate deja efectuată',
        session_descr:
            "Reamintim că, conform reglementărilor în vigoare, este posibil să faceți o pauză de 15'+30' sau 15'+15'+15' (în funcție de tipul de transport efectuat).",
        session_choice: 'Este posibil să faceți cel puțin <b>30m</b> în următoarele',
        brake_start: 'Începutul pauzei',
        brake_done: 'Pauză efectuată',
        daily: 'Zilnică',
        weekly: 'Săptămânală',
        driver_in_drive: 'Tu treci cu mașina',
        driver_in_rest: 'Repaus rezidual',
        driver_in_work: 'Muncă',
        driver_in_available: 'Disponibilitate',
        driver_in_availability: 'Disponibilitate',
        current_activity: 'Activitate curenta',
        mini_plafond: 'Mini-tavan',
        last_update: 'Ultima actualizare',
        panel_title: 'Panou de tavan',
        residual_commitment: 'Angajament rezidual',
        daily_driving: 'Ghid zilnic',
        weekly_guide: 'Ghid săptămânal',
        biweekly_guide: 'Ghid bisăptămânal',
        last_rest: 'Ultima odihnă săptămânală luată',
        minimal_duration_next: 'Urmează durata minimă',
        top_start_until: 'Pentru a fi început de',
        reduced_weekly_rest: 'Perioade de odihnă săptămânale reduse pentru a compensa',
        time_to_compensate: 'E timpul să compensezi',
        time_to_compensate_until: 'Pentru a fi compensat de',
        footer_message: '* timpul se calculează luând în considerare bonusurile reziduale',
        driver_in_block: 'Limita șoferului epuizată',
        résidu_commitment: 'Angajament rezidual',
        week_guide: 'Ghid Hebdomadaire',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'Eroare 403',
            description:
                'Stimate client, vă informăm că contul dumneavoastră nu este configurat în prezent pentru a accesa acest serviciu. Pentru a primi informații despre serviciul în sine',
            retry_login: 'Vă rugăm să încercați să vă conectați din nou',
            contact_us: 'Contactaţi-ne'
        }
    },
    ptv: {
        maxpermittedweight: 'Greutate',
        minpermittedweight: 'Greutate minima',
        zonename: 'Zonă',
        unauthorizedtype: 'Cod neautorizat',
        maxemissionstandardeu: 'Clasa de emisii',
        fueltype: 'Tipul combustibilului',
        loadtype: 'Tipul de transport',
        opening: 'plimbare',
        maxheight: 'Inaltime maxima',
        maxweight: 'Greutate',
        maxwidth: 'Latime maxima',
        maxlength: 'Lungime maxima',
        absolutespeed: 'Viteză',
        hazardoustowaters:
            'Interzicerea vehiculelor care transportă mărfuri periculoase pentru apă',
        hazardousgoods: 'Interdicție pentru vehiculele cu mărfuri periculoase',
        combustibles: 'Interzicerea vehiculelor care transportă combustibil',
        maxaxleload: 'Greutate maximă pe osii',
        totalpermittedweight: 'Greutate',
        tunnelrestriction: 'Cod de restricție pentru tuneluri',
        kingpintorearaxle: 'Distanța de la pivot la ax',
        numberofaxles: 'Numărul de axe',
        length: 'Coadă',
        delay: 'Întârziere',
        message: {
            text: 'Mesaj'
        }
    },
    options_interface: 'Interfață cu opțiuni'
}
