export default {
    open: 'Abre',
    close: 'Cerca',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    search: 'Cerca',
    cancel_search: 'Cancelar búsqueda',
    edit_search: 'Editar búsqueda',
    erase: 'Cancelar',
    back_to_results: 'Volver a los resultados',
    save: 'Ahorrar',
    retry: 'Intentar otra vez',
    click_here: 'haga clic aquí',
    copy: 'Copiar',
    copied: 'copiado',
    copied_coordinates: 'Coordenadas copiadas',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    selected_plural: 'Seleccionado',
    deselect: 'Deseleccionar',
    select_multi_vehicles: 'Seleccione uno o más vehículos',
    include: 'Incluir',
    exclude: 'Excluir',
    balanced: 'Equilibrado',
    input: 'Entrada',
    output: 'Salida',
    verified: 'Verificado',
    empty_search: 'No se han encontrado resultados',
    read_more: 'Leer más',
    details: 'Detalles',
    actual: 'Actual',
    residual_time: 'Tiempo restante',
    next: 'Próximo',
    previous: 'Anterior',
    menu_open: 'Menú abierto',
    hide: 'Esconder',
    show: 'Espectáculo',
    none: 'Nadie',
    none_alt: 'Ninguno',
    all: 'Todos',
    all_alt: 'Todo',
    default: 'Por defecto',
    settings: 'Ajustes',
    reset_settings: 'Reiniciar ajustes',
    reset_settings_success: 'La configuración se restableció correctamente',
    multiselect_default_value: {
        singular: 'Valor seleccionado:',
        plural: 'Valores seleccionados:'
    },
    available: 'Disponible',
    unavailable: 'No disponible',
    not_available: 'No disponible',
    not_available_plural: 'No disponible',
    from: 'desde el',
    to: 'hacia',
    of: 'De',
    yes: 'Sí',
    no: 'No',
    minutes: 'Minutos',
    hours: 'Horas',
    hour: 'Ahora',
    days: 'Días',
    day: 'Día',
    months: 'Meses',
    month: 'Mes',
    years: 'Años',
    year: 'Año',
    days_names: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    days_names_short: ['Sol', 'Lun', 'Mar', 'Casarse', 'jueves', 'Vie', 'Se sentó'],
    months_names: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Puede',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ],
    months_names_short: [
        'generación',
        'Feb',
        'Mar',
        'Abr',
        'Revista',
        'Abajo',
        'Jul',
        'Ago',
        'Colocar',
        'Oct',
        'Nov',
        'Dic'
    ],
    areas: 'Áreas',
    area: 'Área',
    time: 'Tiempo (minutos)',
    time_label: 'Tiempo',
    cost: 'Costo',
    toll_cost: 'Costo del peaje',
    km_cost: 'Costo por km',
    hourly_cost: 'Costo por hora',
    working_cost: 'Costo total del hombre',
    total_cost: 'Coste total',
    expire_date: 'Fecha de caducidad',
    start_date: 'Fecha de inicio',
    recap: 'Resumen',
    start: 'Comenzar',
    starting_point: 'Partida',
    end: 'fin',
    finish_point: 'llego',
    duration: 'Duración',
    distance: 'Distancia',
    daily_distance: 'Km recorridos diariamente',
    distance_total: 'Distancia total',
    gps_distance: 'Distancia (GPS)',
    distance_gps: 'Km recorridos diariamente (GPS)',
    pause: 'Romper',
    segment: 'Trata con',
    start_time: 'Hora de inicio',
    end_time: 'Hora de finalización',
    service_time: 'Tiempo de servicio',
    waypoint_load: 'Cargando a bordo',
    state: 'Estado',
    violated_plafond: 'Violación del techo',
    traffic_delay: 'Retraso en el tráfico',
    company: 'Agencia',
    address: 'DIRECCIÓN',
    position: 'Posición',
    route: 'Camino',
    filter_date: 'Filtrar por fecha',
    warning: 'Atención',
    no_warning: 'Sin advertencias',
    alerts: 'Avisos',
    trucks_with_alerts: 'Vehículos con advertencias',
    bluetooth_devices: 'Dispositivos Bluetooth',
    no_bluetooth_devices: 'No se encontraron dispositivos Bluetooth',
    connected: 'Conectado',
    disconnected: 'Desconectado',
    bluetooth_types: {
        temperature_probe: 'Probeta de temperatura',
        door_probe: 'Sonda de puerta y temperatura'
    },
    back_to_home: 'De vuelta a casa',
    cerca: 'Cerca',
    search_vehicle: 'Buscar vehículo',
    filter: 'Filtrar',
    filter_label: 'Filtrar',
    filters_label: 'Filtros',
    filter_by: 'Filtrado por',
    operations: 'Operaciones',
    filter_vehicles: 'Filtrar vehículos',
    filter_applied: 'Filtro aplicado correctamente',
    filter_canceled: 'Filtro eliminado correctamente',
    order_by: 'Ordenar por',
    order: 'Orden',
    ascending: 'Creciente',
    descending: 'Descendente',
    value: 'Valor',
    select_filter: 'Seleccionar filtro',
    sort_vehicles: 'Pedir vehículos',
    sort_for_alphanumeric: 'Alfabético',
    sort_for_lastpoint: 'Cronológico',
    sort_for_company: 'Agencia',
    sort_for_activity: 'Actividad',
    company_select: 'Seleccionar empresa',
    no_companies_founded: 'No se encontraron empresas',
    multicompany: 'Filtrar por empresa',
    no_vehicles_founded: 'No se encontraron vehículos',
    print: 'Prensa',
    export: 'Exportar',
    print_recap: 'Imprimir resumen',
    select_date: 'Selecciona una fecha',
    add: 'agregar',
    add_new: 'Agregar nuevo',
    delete: 'Borrar',
    edit: 'Editar',
    options: 'Opciones',
    login: 'Acceso',
    logout: 'Sales',
    logout_confirm: '¿Seguro que quieres salir?',
    login_button: 'Acceso',
    connect: 'Conectar',
    logged: 'Conectado',
    manage: 'Administrar',
    loading: 'Cargando',
    loading_timeout: 'Error de red. Vuelva a cargar la página o vuelva a intentarlo más tarde.',
    go_back: 'Regresar',
    select_coordinates: 'Seleccionar coordenadas',
    other: 'Otro',
    options_general: 'General',
    options_language: 'Lengua',
    change_interface_language: 'Seleccionar idioma de interfaz',
    change_infringment_language: 'Seleccione el país de infracción',
    change_timezone: 'Selecciona la zona horaria',
    save_language_settings: 'Guardar configuración de idioma',
    vehicle_list_options: 'Lista de vehículos',
    select_view: 'Seleccionar vista',
    select_fields_placeholder: 'Seleccione los campos para ver',
    selected_field: 'Campo seleccionado',
    selected_fields: 'Campos seleccionados',
    list: 'Lista',
    table: 'Mesa',
    view_options: 'Ver opciones',
    fluid_table: 'Ancho dinámico',
    fluid_table_descr: 'Haga que el ancho de la tabla sea fluido según los campos seleccionados',
    vehicle_fields_list: '¿Qué información quieres que aparezca para cada vehículo? (máximo 3)',
    vehicle_fields_list_warning:
        'Atención: el campo no se mostrará si no está disponible para el vehículo',
    vehicle_identification: '¿Cómo quiere que se identifique el vehículo en la lista principal?',
    apply_to_marker: 'Aplicar también esta configuración a los vehículos en el mapa.',
    element_icon_section: 'Icono de elemento',
    element_title_section: 'Título del artículo',
    element_fields_list_section: 'Lista de campos de elementos',
    element_icon_description: 'Seleccione el icono que se mostrará para el artículo',
    element_icon: 'Icono',
    element_icon_color: 'Color del icono',
    options_map: 'Opciones de mapa',
    vehicles_clustering: 'Agrupación de vehículos',
    vehicles_clustering_warning: 'Actualizar esta configuración requiere recargar la página',
    options_marker: 'Opciones de marcador',
    size: 'Dimensión',
    font_size: 'Tamano del texto',
    font_color: 'Color de texto',
    background_color: 'Color de fondo',
    opacity: 'Transparencia',
    color: 'Color',
    small: 'Pequeño',
    medium: 'Medio',
    large: 'Excelente',
    additional_info: 'información adicional',
    analysis_options: 'Análisis de ruta',
    options_chart: 'Opciones de gráfico',
    options_timeline: 'Opciones de línea de tiempo',
    hide_timeline: 'Ocultar línea de tiempo',
    map: 'Mapa',
    silica: 'Sílice',
    google: 'Google',
    google_satellite: 'Satélite de Google',
    sandbox: 'Cajas de arena',
    silkysand: 'arena sedosa',
    gravelpit: 'Pozo de grava',
    open_street_maps: 'Abrir mapas de calles',
    arcgis_satellite: 'Satélite ArcGIS',
    traffic: 'Tráfico',
    heavy_vehicles_ban: 'Prohibiciones de vehículos pesados',
    driver: 'Conductor',
    no_driver: 'Sin conductor',
    drivers: 'Conductores',
    driver_card: 'Tarjeta de conductor',
    name: 'Nombre de pila',
    surname: 'Apellido',
    driver_activity: 'Actividad del conductor',
    driver_status: 'Estado del conductor',
    activity_type: 'tipo de actividad',
    activity: 'Actividad',
    no_activity: 'Ninguna actividad',
    driver_at_rest: 'Conductor en reposo',
    driver_available: 'Conductor disponible',
    driver_at_work: 'conductor en el trabajo',
    driver_driving: 'Conductor conduciendo',
    inactive_vehicle: 'Vehículo inactivo',
    last_driver_detected: 'Último conductor detectado',
    rest: 'Descansar',
    work: 'Trabajar',
    driving: 'Guía',
    availability: 'Disponibilidad',
    codriving: 'Conducir con una tripulación',
    codriver: 'Multitud',
    break: 'Pausar sesión',
    daily_rest: 'Descanso diario',
    violation: 'Violación',
    to_begin_within: 'Para ser iniciado por',
    to_end_within: 'Para terminar por',
    after_total_time_travel: 'Después de un viaje total de',
    after_total_distance_travel: 'Después de una distancia total recorrida de',
    residual_commitment_type: 'Tipo de compromiso residual',
    residual_commitment: 'Compromiso residual',
    commitment_hours: 'Horas de compromiso',
    commitment_hours_description:
        'Encuentra todos los vehículos cuyos conductores tienen un compromiso residual mayor al indicado',
    driver_plafond: 'Techo del conductor',
    residual_driving: 'Orientación residual',
    residual_bonuses: 'Bonificaciones residuales',
    consider_bonuses: 'El tiempo se calcula considerando las bonificaciones residuales.',
    current_activity: 'Actividad actual',
    next_activity: 'Próxima actividad',
    view_plafond_detail: 'Ver detalle del techo',
    card: 'Papel',
    driver_change: 'Cambio de conductor',
    shared_links: 'Enlaces compartidos',
    gf: 'ENTONCES detectado',
    analyze: 'Analizar',
    plan: 'Plan',
    vehicle: 'Vehículo',
    vehicles: 'Vehículos',
    vehicle_info: 'Información del vehículo',
    vehicle_type: 'Tipo de vehiculo',
    vehicle_body: 'Carrocería del vehículo',
    truck_status: 'Estado del vehículo',
    brand: 'Marca',
    model: 'Modelo',
    no_card: 'Papel no insertado',
    no_card_driving: 'Conducción sin papel',
    speed: 'Velocidad',
    tacho_speed: 'Velocidad (tacógrafo)',
    vehicle_hooked: 'Vehículo atado',
    truck_hooked_text: 'Este vehículo está atado al siguiente remolque: ',
    trailer_hooked_text: 'Este remolque está atado al siguiente vehículo: ',
    no_driver_rfid_info: 'La tarjeta del conductor no está registrada en la lista de conductores',
    gps_speed: 'Velocidad (GPS)',
    level: 'Nivel',
    battery: 'Batería',
    low_battery_level: 'Nivel de batería bajo',
    fuel: 'Combustible',
    fuel_level: 'Nivel de combustible',
    low_fuel_level: 'Bajo combustible',
    consumption: 'Consumo',
    consumptions: 'Consumo',
    fuel_consumption: 'El consumo de combustible',
    fuel_avg_consumption: 'Consumo medio diario de combustible',
    altitude: 'Altitud',
    weight: 'Peso',
    lastpoint_weight: 'Peso (último punto)',
    vehicle_weight: 'Peso (t)',
    vehicle_length: 'Longitud (m)',
    vehicle_width: 'Ancho (m)',
    vehicle_height: 'Altura (m)',
    odometer: 'Cuentakilómetros',
    odometer_tacho: 'Odómetro (tacógrafo)',
    odometer_gps: 'Odómetro (GPS)',
    gps_odometer: 'Odómetro (GPS)',
    last_point: 'Ultimo punto',
    moving: 'en movimiento',
    stopped: 'Interrumpido',
    engine_on: 'Motor funcionando',
    ignition: 'Encendido conectado',
    engine_off: 'Motor apagado',
    engine_on_off: 'Arrancar el motor',
    ignition_on: 'Encendido conectado',
    ignition_off: 'Encendido apagado',
    device: 'Dispositivo',
    plate: 'Lámina',
    chassis: 'Chasis',
    status: 'Estado',
    custom_id: 'Nombre personalizado',
    heading: 'Dirección',
    rpm_engine: 'Revoluciones del motor',
    pto_active: 'TDF activa',
    pto_state: 'estado de la toma de fuerza',
    device_timestamp: 'Marca de tiempo',
    info: 'Información',
    solar_day_report: 'Informe del día calendario',
    telemetry: 'Telemetria',
    parameters: 'Parámetros',
    engine_coolant_temp: 'Temperatura del motor',
    engine_rpm: 'Revoluciones del motor',
    dallas_temp_1: 'Temperatura 1',
    dallas_temp_2: 'Temperatura 2',
    dallas_temp_id_1: 'Identificación de temperatura 1',
    dallas_temp_id_2: 'Identificación de temperatura 2',
    digital_input_1: 'Entrada digital 1',
    digital_input_2: 'Entrada digital 2',
    digital_input_3: 'Entrada digital 3',
    digital_input_4: 'Entrada digital 4',
    digital_input_5: 'Entrada digital 5',
    battery_level: 'Nivel de bateria',
    adblue_liters: 'Litros Adblue',
    adblue_level: 'nivel de adblue',
    gnss_status: 'Estado GNSS',
    gnss_status_values: [
        'Desgastado',
        'Encendido, sin GPS',
        'Encendido, sin satélites',
        'Encendido, con satélites',
        'encendido, en el sueño',
        'Estado protegido'
    ],
    gnss_off: 'GNSS desactivado',
    gnss_on_fix: 'GNSS activado, con satélites',
    gnss_on_without_fix: 'GNSS activado, sin satélites',
    gnss_on_sleep: 'GNSS activado, en reposo',
    gnss_on_protection: 'GNSS activado, estado de protección',
    gsm_signal: 'señal gsm',
    tacho_source: 'fuente del tacógrafo',
    external_voltage: 'Dieta',
    tacho_overspeed: 'Tacógrafo de exceso de velocidad',
    no_points_title: 'Última actividad hace más de 24 horas',
    no_points: 'Este vehículo no ha enviado puntos durante más de 24 horas',
    clutch: 'Embrague',
    clutch_switch: 'Interruptor del embrague',
    brake_pedal: 'Pedal de freno',
    accelerator_pedal: 'Pedal acelerador',
    pedal_released: 'Pedal liberado',
    pedal_pressed: 'Pedal presionado',
    data_mode: 'Modo de datos',
    home_stop: 'Bloqueado en su lugar',
    home_moving: 'En movimiento en el sitio',
    roaming_stop: 'Atascado en itinerancia',
    roaming_moving: 'En movimiento itinerante',
    unknown_stop: 'Arresto desconocido',
    unknown_moving: 'Moviéndose desconocido',
    engine_coolant_temperature: 'Temperatura refrescante',
    engine_temperature: 'Temperatura del motor',
    cruise_control_active: 'Control de crucero activo',
    adblue_off: 'Adblue apagado',
    adblue_red: 'Adblue rojo',
    adblue_yellow: 'Adblue amarillo',
    adblue_info: 'Información sobre adblue',
    charger_notconnected: 'Cargador no conectado',
    charger_connected: 'Cargador conectado',
    tacho_unknown: 'Desconocido',
    tacho_kline: 'Línea K',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'El vehículo al que intentas acceder no está disponible',
    vehicle_with_lock_commands: 'Vehículo con controles de bloqueo.',
    lock: 'Bloquear',
    unlock: 'desbloquear',
    send: 'Enviar',
    command_sent: 'Comando enviado exitosamente',
    turn_on: 'luces encendidas',
    turned_on: 'Encendido',
    turn_off: 'Apagar',
    turned_off: 'Desgastado',
    confirm_send_command: '¿Estás seguro de que quieres enviar el comando?',
    temperature: {
        title: 'Sondas de temperatura',
        probe: ['Sonda 1', 'Sonda 2', 'Sonda 3', 'Sonda 4', 'Sonda 5', 'Sonda 6']
    },
    no_power_supply: 'Ninguna energía',
    no_gps: 'Sin GPS',
    tacho_clock_from_updated_error: 'Error de sincronización del reloj del tacógrafo',
    tacho_clock_from_device_error:
        'Error de sincronización entre el tacógrafo y la unidad de control',
    device_timestamp_error: 'Error de fecha de la ECU',
    select_color_mode: 'Seleccionar modo de color',
    light_mode: 'Modo de luz',
    dark_mode: 'modo oscuro',
    color_blind_mode: 'Modo daltónico',
    color_blind_dark_mode: 'Modo oscuro daltónico',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Vehículos destacados',
            search_trucks_title: 'Buscar vehículos',
            search_trucks_tags_title: 'Etiquetas asociadas',
            search_trucks_status_title: 'Actividad del vehículo',
            search_trucks_driver_status_title: 'Actividad del conductor',
            search_near_trucks_title: 'Encuentra vehículos cercanos'
        },
        detailspanel: {
            bind_vehicle: 'Par de vehículos',
            add_new_tag: 'Agregar como nueva etiqueta',
            select_new_tag: 'Seleccionar o buscar una etiqueta',
            binded_tags: 'Etiquetas asociadas',
            binded_tag: 'Etiqueta asociada'
        },
        analyzer: {
            vehicle_activity_analisys: 'Análisis de actividad del vehículo.',
            timeline_separator: 'Agrupar por',
            loading_error: 'Se produjo un error al cargar datos.'
        },
        search_resources: {
            title: 'Buscar recursos',
            description:
                'Encuentra las gasolineras, parkings y zonas accesibles más cercanas a tu vehículo.'
        }
    },
    analisys: {
        title: 'Análisis de ruta',
        select_telemetries: 'Seleccionar las telemetrías a mostrar en el análisis de ruta.',
        select_telemetries_description:
            'Es posible seleccionar un máximo de 4 telemetrías para visualizar',
        chart_groups: {
            movement_data: 'Datos de movimiento',
            engine_data: 'Datos del motor',
            vehicle_data: 'Datos del vehículo',
            sensors_data: 'Datos del sensor',
            driver_data: 'Datos del conductor',
            fuel_data: 'Datos de combustible'
        },
        no_data: 'No hay datos disponibles',
        no_data_description:
            'No hay datos de ruta para mostrar para este vehículo o para el período seleccionado',
        no_data_contact_support:
            'Póngase en contacto con el servicio de atención al cliente para obtener más información.'
    },
    planner: {
        user_config: 'Configuraciones de usuario',
        no_user_config: 'No hay configuraciones de usuario para ver',
        freight_access: 'Acceso a bolsas de carga.',
        vehicle_actual_position: 'Posición actual del vehículo',
        plan_route: 'Planificar ruta',
        select_on_map: 'Seleccione un punto en el mapa',
        select_on_map_option: 'Seleccionar en el mapa',
        fill_route_info: 'Complete la información de la ruta',
        fill_resources: 'Seleccione el punto para agregar como waypoint',
        fill_waypoints: 'Selecciona las etapas',
        load_breaks: 'La carga descansa',
        load_alternative_routes: 'Cargar rutas alternativas',
        savedroutes: {
            my_routes: 'Mis rutas',
            saved_routes: 'Rutas guardadas',
            no_saved_routes: 'Aún no has guardado ninguna ruta',
            success_saved_route: 'Tu ruta se ha guardado correctamente.',
            error_saved_route: 'Se produjo un error al guardar la ruta.',
            success_deleted_route: 'Tu ruta ha sido eliminada exitosamente',
            error_deleted_route: 'Se produjo un error al eliminar la ruta.'
        },
        resources: {
            area_search: 'Buscar dentro del radio',
            station_services: 'Incluidos estos servicios.',
            parking_services: 'Servicios de aparcamiento',
            select_rating: 'Selecciona la puntuación',
            select_security: 'Seleccionar seguridad',
            empty_search: 'La búsqueda no encontró ningún elemento para mostrar.',
            delivery_nation: 'País de entrega',
            fai_featured: 'afiliado a lumesia',
            self_service: 'Autoservicio',
            served: 'servido',
            total_parking_spots: 'Plazas totales',
            rating: 'Devengo',
            reviews: 'Reseñas',
            security: 'Seguridad',
            price_per_night: 'Precio por noche'
        },
        emissions_labels: 'Emisiones',
        emissions: {
            fuelconsumption: 'El consumo de combustible',
            hydrocarbons: 'Hidrocarburos',
            methane: 'Metano',
            hydrocarbonsexceptmethane: 'Hidrocarburos excepto metano',
            carbonmonoxide: 'Monóxido de carbono',
            carbondioxide: 'Dióxido de carbono',
            fossilcarbondioxide: 'Dióxido de carbono fósil',
            sulphurdioxide: 'Dióxido de azufre',
            nitrogenoxides: 'Oxido de nitrógeno',
            nitrogendioxide: 'Dioxido de nitrogeno',
            nitrousoxide: 'Óxido nitroso',
            ammonia: 'Amoníaco',
            benzene: 'Benceno',
            particles: 'Partículas',
            numberofparticles: 'Número de partículas'
        },
        plafond_configuration: {
            title: 'Configuración de techo',
            driving_time_regulation: 'Tiempo de conducción',
            last_time_the_driver_worked: 'Última actividad del conductor',
            eu_driving_time_regulation_for_single_day: 'solo dia',
            eu_driving_time_regulation_for_single_long_day: 'Día único (con bono)',
            eu_driving_time_regulation_for_multiple_day: 'Más días',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Varios días (con tripulación)',
            accumulated_driving_time_since_lastbreak:
                'Horas de conducción desde el último descanso',
            accumulated_driving_time_since_lastdailyrest:
                'Horas de conducción desde el último descanso diario',
            accumulated_travel_time_since_lastdailyrest:
                'Horas de viaje desde el último descanso diario'
        },
        set_route: 'Establecer ruta',
        select_profile: 'Seleccionar un perfil',
        select_co2_class: 'Seleccionar clase de emisión',
        class: 'Clase',
        billed_roads: 'Carreteras de peaje',
        waypointevent: 'Detener',
        waypoints_label: 'Etapas',
        add_waypoint: 'Agregar etapa',
        delete_waypoint: 'Eliminar etapa',
        select_waypoint: 'Seleccionar etapa',
        select_resource: 'Seleccione el punto para agregar como waypoint',
        last_waypoint: 'Última parada',
        waypoints_check: 'Hay etapas para completar.',
        waypoints_required: 'Son necesarios al menos 2 pasos',
        countryevent: 'Estado',
        tollevent: 'Cabina de peaje',
        event: 'Evento',
        alpega: 'alpega',
        alpega_login: 'Iniciar sesión',
        alpega_searches: 'Tu investigación',
        timocom: 'Timocom',
        pickup: 'Retirar',
        delivery: 'Entrega',
        extimated_cost: 'Costo estimado',
        calculate_route: 'Calcular ruta',
        calculate_isocrona: 'Calcular isócrona',
        complete_step_2: 'Completa el paso 2',
        reset_route: 'Nueva ruta',
        route_error: 'Se produjo un error al calcular la ruta.',
        edit_route: 'Editar ruta',
        save_route: 'guardar ruta',
        delete_route: 'Eliminar ruta',
        route_name: 'Nombre de ruta',
        override_route: 'Sobrescribir ruta',
        save_as_new_route: 'Guardar nueva ruta',
        calculate_route_for: 'Calcular ruta para',
        ptvexport: 'Exportar al navegador PTV',
        isocrona: 'Áreas accesibles',
        reset_isocrona: 'Restablecer áreas accesibles',
        search_location: 'Buscar ubicaciones',
        search_station: 'Buscar distribuidor',
        search_parking: 'buscar aparcamiento',
        search_poi: 'Buscar PDI',
        include_plafond: 'Incluir límite de conductores en el cálculo de ruta',
        include_plafond_near_vehicles:
            'Encuentre vehículos con conductores con suficiente compromiso restante para llegar al lugar buscado',
        violated_plafond: 'La ruta seleccionada viola el límite del conductor.'
    },
    sharedlinks: {
        back_to_links: 'Volver a los enlaces',
        create_link: 'Crear enlaces',
        update_link: 'Enlace de actualización',
        edit_link: 'Editar enlace',
        update_link_expire_date: 'Actualizar fecha de vencimiento',
        delete_link: 'Eliminar enlace',
        delete_link_confirm: '¿Eliminar el enlace seleccionado?',
        all_your_shared_links: 'Todos tus enlaces compartidos',
        no_shared_links: 'Aún no has compartido ningún vehículo',
        vehicles_link: 'Enlaces para vehículos',
        select_share_vehicles: 'Selecciona vehículos para compartir',
        select_start_expire_date: 'Selecciona fecha de inicio y caducidad',
        created_link_error: 'Error al crear el enlace',
        created_link_success: 'Enlace creado exitosamente',
        updated_link_error: 'Error al actualizar el enlace',
        updated_link_success: 'Enlace actualizado exitosamente',
        service_description:
            'Comparta este enlace para mostrar el seguimiento de los vehículos seleccionados',
        link_preview: 'Vista previa del enlace',
        share_single_vehicle: 'Compartir la ubicación de este vehículo',
        track_vehicle_position: 'Seguimiento de la ubicación de los vehículos seleccionados',
        start_date_warning: 'No es posible cambiar la fecha de inicio.',
        start_date_condition: 'Si desea cambiar la fecha de inicio, debe crear un nuevo enlace',
        expire_date_description:
            'Indica una fecha de vencimiento, después de la cual este enlace ya no estará disponible',
        start_expire_date_description:
            'Indique una fecha inicial a partir de la cual este enlace estará disponible y una fecha de vencimiento después de la cual este enlace dejará de estar disponible:'
    },
    viewsharedlink: {
        invalid_date_range: 'El rango de fechas que seleccionó no es válido para este enlace',
        too_early_link: 'El enlace al que intentas acceder aún no está disponible',
        too_late_link: 'El enlace al que intentas acceder ya no existe',
        show_route: 'Mostrar ruta'
    },
    tags: {
        manage_tags: 'Administrar etiquetas',
        add_tag: 'Agregar etiquetas',
        add_tag_placeholder: 'Introduzca el nombre de la etiqueta',
        add_tag_error: 'Error al agregar la etiqueta',
        add_tag_success: 'Etiqueta agregada exitosamente',
        delete_tag: 'Eliminar etiquetas',
        delete_tag_confirm: '¿Eliminar la etiqueta seleccionada?',
        delete_tag_error: 'Error al eliminar la etiqueta',
        delete_tag_success: 'Etiqueta eliminada correctamente',
        update_tag: 'Actualizar etiquetas',
        update_tag_error: 'Error al actualizar la etiqueta',
        update_tag_success: 'Etiqueta actualizada correctamente',
        all_your_tags: 'Todas tus etiquetas',
        no_tags: 'Aún no has creado ninguna etiqueta',
        tag_name: 'Nombre de etiqueta'
    },
    manage_poi: {
        title: 'Administrar puntos de interés',
        your_pois: 'Tus puntos de interés',
        add_poi: 'Agregar PDI',
        no_pois: 'Aún no has creado ningún PDI',
        fields: {
            name: 'Nombre de pila',
            type: 'Chico',
            color: 'Color',
            note: 'Nota',
            address: 'Buscar dirección',
            latitude: 'Latitud',
            longitude: 'Longitud',
            radius: 'Radio de búsqueda',
            radius_description: 'Para habilitar la geocerca, establezca un valor mayor que cero'
        },
        types_poi: {
            all: 'Todos',
            headquarter: 'Sitio',
            truck_washes: 'Lavaderos de camiones',
            blacklist: 'Lista negra',
            end_of_the_line: 'Término',
            customers: 'Clientela',
            stops: 'Detener',
            suppliers: 'Proveedores',
            interports: 'Interpuertos',
            markets: 'Mercados',
            large_square: 'Cuadrícula',
            poi_distributors: 'ENTONCES Distribuidores',
            poi_workshops: 'ENTONCES Talleres',
            harbors: 'Puertos',
            restaurants: 'Restaurantes',
            stations: 'Estaciones'
        },
        messages: {
            add_poi_error: 'Error al agregar puntos de interés',
            add_poi_success: 'ENTONCES agregado exitosamente',
            update_poi_error: 'Error al actualizar el punto de interés',
            update_poi_success: 'ENTONCES se actualizó exitosamente',
            delete_poi_error: 'Error al eliminar puntos de interés',
            delete_poi_success: 'ENTONCES eliminado con éxito'
        }
    },
    vehicles_cross: {
        title: 'Paso de vehículos',
        fields: {
            address: 'DIRECCIÓN',
            radius: 'radio',
            time_interval: 'Lapso de tiempo',
            time_interval_description: 'Seleccione un rango de tiempo máximo de 24 horas'
        },
        messages: {
            max_time_interval: 'El rango de tiempo máximo es de 24 horas.',
            error_searching: 'Se produjo un error al buscar vehículos.',
            searching: 'Investigación en progreso...',
            long_loading: 'La búsqueda puede tardar unos minutos, por favor espera...',
            no_vehicles_cross: 'No se encontraron vehículos'
        }
    },
    plafonds: {
        session: 'Pausa de sesión',
        break: 'Pausa',
        drive_occurring: 'Está ocurriendo un Periodo de Conducción:',
        drive_max_label: 'Tiempo máximo de conducción',
        drive_max_value: '4h 30m',
        drive_done: 'Conducción realizada',
        session_title: 'Información sobre la Pausa Fraccionada',
        session_tooltip: 'Es posible realizar una Pausa Fraccionada',
        session_done: 'Se ha detectado una pausa de al menos 15 minutos.',
        session_info: 'Primera parte de una Pausa Fraccionada ya realizada',
        session_descr:
            "Recordamos que según las normativas vigentes, es posible realizar una pausa de 15'+30' o 15'+15'+15' (según el tipo de transporte realizado).",
        session_choice: 'Es posible realizar al menos <b>30m</b> dentro de las próximas',
        brake_start: 'Inicio de la pausa',
        brake_done: 'Pausa realizada',
        daily: 'Diaria',
        weekly: 'Semanal',
        driver_in_drive: 'Estás conduciendo por',
        driver_in_rest: 'Descanso residual',
        driver_in_work: 'Trabajando',
        driver_in_available: 'Disponible',
        driver_in_availability: 'Disponible',
        current_activity: 'Actividad actual',
        mini_plafond: 'Mini-techo',
        last_update: 'Última actualización',
        panel_title: 'Panel de techo',
        residual_commitment: 'Compromiso residual',
        daily_driving: 'guía diaria',
        weekly_guide: 'guía semanal',
        biweekly_guide: 'guía quincenal',
        last_rest: 'Último descanso semanal tomado',
        minimal_duration_next: 'Duración mínima siguiente',
        top_start_until: 'Para ser iniciado por',
        reduced_weekly_rest: 'Periodos de descanso semanales reducidos para compensar',
        time_to_compensate: 'Es hora de compensar',
        time_to_compensate_until: 'Ser compensado por',
        footer_message: '* el tiempo se calcula considerando las bonificaciones residuales',
        driver_in_block: 'Límite de conductores agotado',
        résidu_commitment: 'Compromiso residual',
        week_guide: 'guía hebdomadaire',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'error 403',
            description:
                'Estimado cliente, le informamos que su cuenta no se encuentra actualmente configurada para acceder a este servicio. Para recibir información sobre el propio servicio',
            retry_login: 'Por favor intenta iniciar sesión nuevamente',
            contact_us: 'Contáctenos'
        }
    },
    ptv: {
        maxpermittedweight: 'De peso pesado',
        minpermittedweight: 'Peso mínimo',
        zonename: 'Área',
        unauthorizedtype: 'Código no autorizado',
        maxemissionstandardeu: 'Clase de emisión',
        fueltype: 'Tipo de combustible',
        loadtype: 'Tipo de transporte',
        opening: 'Conducir',
        maxheight: 'Altura máxima',
        maxweight: 'De peso pesado',
        maxwidth: 'Ancho máximo',
        maxlength: 'Longitud máxima',
        absolutespeed: 'Velocidad',
        hazardoustowaters:
            'Prohibición de vehículos que transporten mercancías peligrosas para el agua',
        hazardousgoods: 'Prohibición de vehículos con mercancías peligrosas',
        combustibles: 'Prohibición de vehículos que transporten combustible',
        maxaxleload: 'Peso máximo sobre los ejes',
        totalpermittedweight: 'De peso pesado',
        tunnelrestriction: 'Código de restricción de túneles',
        kingpintorearaxle: 'Distancia del pivote al eje',
        numberofaxles: 'Número de ejes',
        length: 'Cola',
        delay: 'Demora',
        message: {
            text: 'Mensaje'
        }
    },
    options_interface: 'Interfaz de opciones'
}
