<template>
    <div>
        <div class="container p-md-0">
            <ul>
                <li @click="layerSelected(recap)" v-if="recap">
                    <div>
                        <div class="title">
                            <i class="fa-solid fa-book"></i>&nbsp;{{ __('recap') }}
                        </div>
                        <div class="info">
                            <div>
                                <b>{{ __('start') }}</b
                                >: {{ moment(recap.start_date, 'UTC').format('L HH:mm:ss') }}
                            </div>

                            <div class="mt-2">
                                <i class="fa-solid fa-location-dot float-left me-1"></i>
                                <reverse-geocoding
                                    class="reverse-address"
                                    :latLon="recap.extremes[0]"
                                />
                            </div>

                            <div>
                                <b>{{ __('end') }}</b
                                >: {{ moment(recap.end_date, 'UTC').format('L HH:mm:ss') }}
                            </div>
                            <div class="my-2">
                                <i class="fa-solid fa-location-dot float-left me-1"></i>
                                <reverse-geocoding
                                    class="reverse-address"
                                    :latLon="recap.extremes[1]"
                                />
                            </div>

                            <div>
                                <b>{{ __('duration') }}</b
                                >: {{ parseInt(recap.duration) | minutesToTime }} h
                            </div>
                            <div v-if="recap.distance">
                                <b>{{ __('distance') }}</b
                                >: {{ recap.distance.toFixed(0) }} Km
                            </div>
                            <div v-if="recap.gps_distance">
                                <b>{{ __('gps_distance') }}</b
                                >: {{ recap.gps_distance.toFixed(0) }} Km
                            </div>
                            <div v-if="recap.fuel_consumption">
                                <b>{{ __('fuel_consumption') }}</b
                                >: {{ recap.fuel_consumption }} l
                            </div>

                            <button
                                @click="$emit('print')"
                                class="btn-print btn btn-sm btn-fill dark-blue my-2"
                                v-if="$mq !== 'xs' && $mq !== 'sm'"
                            >
                                <i class="fa-solid fa-print"></i> {{ __('print_recap') }}
                            </button>
                        </div>
                    </div>
                </li>
                <li @click="layerSelected(recap, null, [recap.extremes[0]])" v-if="timeline.length">
                    <div>
                        <div class="title">
                            <i class="fa-solid fa-flag-checkered"></i>&nbsp;{{
                                __('starting_point')
                            }}
                        </div>
                        <div class="info">
                            <div>
                                {{ moment(timeline[0].start_date, 'UTC').format('L HH:mm:ss') }}
                            </div>
                            <div class="my-2">
                                <i class="fa-solid fa-location-dot float-left me-1"></i>
                                <reverse-geocoding
                                    class="reverse-address"
                                    :latLon="[
                                        timeline[0].extremes[0].lat,
                                        timeline[0].extremes[0].lon
                                    ]"
                                    v-if="timeline[0].extremes[0].lat"
                                />
                            </div>
                        </div>
                    </div>
                </li>
                <li
                    v-for="(segment, i) in timeline"
                    :key="i"
                    :id="'segment-' + i"
                    :class="{ 'segment-selected': segment.selected }"
                    @click="
                        layerSelected(
                            segment,
                            [
                                moment(segment.start_date, 'UTC').valueOf(),
                                moment(segment.end_date, 'UTC').valueOf()
                            ],
                            [
                                [segment?.extremes[0]?.lat, segment?.extremes[0]?.lon],
                                [segment?.extremes[1]?.lat, segment?.extremes[1]?.lon]
                            ]
                        )
                    "
                >
                    <div>
                        <div class="title">
                            <span v-if="segment.type == 'stop'">
                                <i class="fa-solid fa-bed"></i>&nbsp;{{ __('pause') }}
                            </span>
                            <span v-else-if="segment.type == 'driver'">
                                <i class="fa-duotone fa-user-group-simple"></i>&nbsp;{{
                                    __('driver_change')
                                }}
                            </span>
                            <span v-else-if="segment.type == '0'">
                                <i class="fa-solid fa-bed"></i>&nbsp;{{ __('rest') }}
                            </span>
                            <span v-else-if="segment.type == '1'">
                                <i class="fak fa-availability"></i>&nbsp;{{ __('availability') }}
                            </span>
                            <span v-else-if="segment.type == '2'">
                                <i class="fak fa-work"></i>&nbsp;{{ __('work') }}
                            </span>
                            <span v-else-if="segment.type == '3'">
                                <i class="fa-solid fa-steering-wheel"></i>&nbsp;{{ __('driving') }}
                            </span>
                            <span v-else-if="segment.type == '4'">
                                <i class="fa-solid fa-ban"></i>&nbsp;{{ __('no_activity') }}
                            </span>
                            <span v-else>
                                <i class="fa-solid fa-road"></i>&nbsp;{{ __('segment') }}
                            </span>
                            <span
                                class="close-button"
                                v-show="segment.selected"
                                @click.stop="layerDeselected(segment)"
                            >
                                <i class="fa fa-close" style="font-size: 20px"></i>
                            </span>
                        </div>
                        <div class="info">
                            <div>
                                <b>{{ __('start') }}</b
                                >: {{ moment(segment.start_date, 'UTC').format('L HH:mm:ss') }}
                            </div>
                            <div class="my-2" v-if="segment.extremes && segment?.extremes[0]?.lat">
                                <i class="fa-solid fa-location-dot float-left me-1"></i>
                                <reverse-geocoding
                                    class="reverse-address"
                                    :latLon="[segment?.extremes[0]?.lat, segment?.extremes[0]?.lon]"
                                />
                            </div>

                            <div v-if="segment.end_date">
                                <b>{{ __('end') }}</b
                                >: {{ moment(segment.end_date, 'UTC').format('L HH:mm:ss') }}
                            </div>
                            <div class="my-2" v-if="segment.extremes && segment?.extremes[1]?.lat">
                                <i class="fa-solid fa-location-dot float-left me-1"></i>
                                <reverse-geocoding
                                    class="reverse-address"
                                    :latLon="[segment?.extremes[1]?.lat, segment?.extremes[1]?.lon]"
                                />
                            </div>

                            <template v-if="segment.type == 'driver'">
                                <div>
                                    <b>{{ __('driver') }}</b
                                    >: {{ segment.name }} {{ segment.last_name }}
                                </div>
                                <div>
                                    <b>{{ __('card') }}</b
                                    >: {{ segment.card }}
                                </div>
                            </template>

                            <template v-if="segment.type != 'driver'">
                                <div>
                                    <b>{{ __('duration') }}</b
                                    >: {{ parseInt(segment.duration) | minutesToTime }} h
                                </div>
                                <div v-if="segment.distance !== null && segment.distance > 0">
                                    <b>{{ __('distance') }}</b
                                    >: {{ parseFloat(segment.distance).toFixed(0) }} Km
                                </div>
                                <div
                                    v-if="segment.gps_distance !== null && segment.gps_distance > 0"
                                >
                                    <b>{{ __('gps_distance') }}</b
                                    >: {{ parseFloat(segment.gps_distance).toFixed(0) }} Km
                                </div>
                                <div
                                    v-if="
                                        segment.fuel_consumption !== null &&
                                        segment.fuel_consumption > 0
                                    "
                                >
                                    <b>{{ __('fuel_consumption') }}</b
                                    >: {{ segment.fuel_consumption }} l
                                </div>
                            </template>
                        </div>
                    </div>
                    <span v-if="segment.type == 'moving'" class="number"></span>
                </li>
                <li @click="layerSelected(recap, null, [recap.extremes[1]])" v-if="recap">
                    <div>
                        <div class="title">
                            <i class="fa-solid fa-flag-checkered"></i>&nbsp;{{ __('finish_point') }}
                        </div>
                        <div class="info">
                            <div>{{ moment(recap.end_date, 'UTC').format('L HH:mm:ss') }}</div>

                            <div class="my-2">
                                <i class="fa-solid fa-location-dot float-left me-1"></i>
                                <reverse-geocoding
                                    class="reverse-address"
                                    :full="true"
                                    :latLon="recap.extremes[1]"
                                />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TimelineList',
    props: {
        truck: {
            type: Object,
            required: true
        },
        range: {
            type: Object,
            required: false
        },
        break: {
            type: Number,
            required: false,
            default: 15
        },
        type: {
            type: Number,
            required: false,
            default: 1
        },
        showRouteAction: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    components: {},
    data() {
        return {
            timeline: [],
            recap: null
        }
    },
    watch: {
        truck() {
            this.createTimeline()
        },
        range: {
            handler() {
                this.createTimeline()
            },
            deep: true
        },
        type() {
            this.createTimeline()
        },
        break() {
            this.createTimeline()
        },
        timeline: {
            handler() {
                let cn = document.querySelector('.timeline')

                this.$nextTick(() => {
                    let el = document.querySelector('.segment-selected')

                    if (cn && el)
                        cn.scrollTo({ top: el.offsetTop - cn.offsetTop, behavior: 'smooth' })
                })
            },
            deep: true,
            immediate: true
        },
        showRouteAction() {
            this.$emit('reset')
            this.$emit('input', {})
            this.timeline = []
            this.recap = null
        }
    },
    computed: {
        startDate() {
            return this.range.start ? this.range.start.clone().utc().toISOString() : false
        },
        endDate() {
            return this.range.end ? this.range.end.clone().utc().toISOString() : false
        }
    },
    methods: {
        layerDeselected(segment) {
            segment.selected = false

            this.$emit('reset')
        },
        layerSelected(segment, range, bound) {
            this.timeline.map((segment) => {
                segment.selected = false
            })

            segment.selected = true

            this.$emit('selected', { bound: bound, range: range })
        },
        createTimeline() {
            this.$emit('reset')

            if (!(this.startDate && this.endDate)) return false

            let lastpoint_company = this.truck?.lastpoint?.company

            this.$fetch(
                this.$env.GOLIA_API +
                    '/geofleet/trucks/' +
                    this.truck.id +
                    '/timeline?start_date=' +
                    this.startDate +
                    '&end_date=' +
                    this.endDate +
                    '&timespan=' +
                    this.break +
                    '&type=' +
                    this.type +
                    '&company=' +
                    lastpoint_company +
                    '&include=driver[]'
            )
                .then((res) => {
                    return res.json()
                })
                .then((body) => {
                    // let drivers_data = [
                    //     {
                    //         name: 'John',
                    //         last_name: 'Driver',
                    //         id: 100,
                    //         card: '1234567890055',
                    //         start_date: '2024-04-15T04:59:08+00:00',
                    //         type: 'driver'
                    //     },
                    //     {
                    //         name: 'Nancy',
                    //         last_name: 'Driver',
                    //         id: 101,
                    //         card: '1234567899849',
                    //         start_date: '2024-04-15T06:59:08+00:00',
                    //         type: 'driver'
                    //     }
                    // ]

                    let drivers_data = []

                    let overall_data = [...body.data, ...drivers_data]

                    this.timeline = overall_data.sort((a, b) => {
                        a.selected = false
                        b.selected = false

                        var startA = new Date(a.start_date).getTime()
                        var startB = new Date(b.start_date).getTime()

                        if (startA < startB) return -1
                        if (startB < startA) return 1
                        return 0
                    })

                    let first = this.timeline.at(0)
                    let last = this.timeline.at(-1)

                    if (first && last)
                        this.recap = Object.assign(
                            {
                                start_date: first.start_date,
                                end_date: last.end_date,
                                extremes: [
                                    [first.extremes[0].lat, first.extremes[0].lon],
                                    [last.extremes[1].lat, last.extremes[1].lon]
                                ],
                                selected: false
                            },
                            this.timeline.reduce(
                                (c, s) => {
                                    c['duration'] += parseFloat(s.duration)
                                    c['distance'] += parseFloat(s.distance)
                                    c['gps_distance'] += parseFloat(s.gps_distance)
                                    c['fuel_consumption'] += s.fuel_consumption
                                        ? parseInt(s.fuel_consumption)
                                        : 0

                                    return c
                                },
                                {
                                    duration: 0,
                                    distance: 0,
                                    gps_distance: 0,
                                    fuel_consumption: 0
                                }
                            )
                        )

                    this.$emit('input', {
                        timeline: this.timeline,
                        recap: this.recap
                    })
                })
        }
    },
    created() {
        this.createTimeline()
    }
}
</script>

<style scoped>
.container ul {
    margin: 16px 0px 20px 10px;
    list-style: none;
    position: relative;
    padding: 1px 10px;
    color: var(--main-text-color);
    font-size: 11px;
}
.container ul:before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    border-left: 2px dashed #c0c5cf;
}
.container ul li {
    position: relative;
    margin-left: 15px;
    background-color: var(--main-inner-bg-color);
    padding: 4px;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(192, 197, 207, 0.12), 0 2px 2px rgba(192, 197, 207, 0.08);
}
.container ul li:not(:first-child) {
    margin-top: 30px;
}
.segment-selected {
    background-color: var(--main-bg-color-card) !important;
}
.container ul li > span {
    width: 2px;
    height: 100%;
    background: #495568;
    left: -15px;
    top: 0;
    position: absolute;
}
.container ul li b {
    font-family: 'Stolzl-Bold', sans-serif;
}
.container ul li span:after {
    top: 100%;
}
.container ul li > div {
    margin-left: 10px;
    margin-right: 10px;
}
.container div .title,
.container div .type {
    font-family: 'Stolzl-Bold';
    font-weight: 600;
    font-size: 12px;
}
.container div .info {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.container div > div {
    margin-top: 5px;
}
.container span.number {
    height: 100%;
}
.container span.number span {
    position: absolute;
    font-size: 10px;
    left: -35px;
    font-weight: bold;
}
.container span.number span:first-child {
    top: 0;
}
.container span.number span:last-child {
    top: 100%;
}
li {
    cursor: pointer;
}
.reverse-address {
    max-width: 250px;
    font-style: italic;
}
.close-button {
    position: absolute;
    right: 8px;
    top: 8px;
}
</style>
